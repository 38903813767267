import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'jobs',
	loader: () => ({
		meta: {
			title: 'Jobs',
			to: { id: 'jobs' },
			htmlTitlePattern: '#title :: Jobs',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/job/Layout')).default }),
	children: [
		{
			path: 'pending',
			index: true,
			id: 'pendingJobs',
			loader: () => ({
				meta: {
					title: 'Pending',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/job/pages/Pending')).default }),
		},
		{
			path: 'schedules',
			index: true,
			id: 'jobSchedules',
			loader: () => ({
				meta: {
					title: 'Schedules',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/job/pages/Schedules')).default }),
		},
		{
			path: '',
			index: true,
			id: 'jobs',
			loader: () => ({
				meta: {
					title: 'All Jobs',
					disabled: true,
					htmlTitlePattern: 'Jobs',
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/job/pages/List')).default }),
		},
	],
};
export default route;
