import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'blast',
	lazy: async () => ({ Component: (await import('@/modules/blast/Layout')).default }),
	children: [
		{
			path: '',
			index: true,
			id: 'blastDatabase',
			loader: () => ({
				meta: {
					title: 'Order Database',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/blast/pages/Blast')).default }),
		},
		{
			path: 'leads',
			index: true,
			id: 'blastLeads',
			loader: () => ({
				meta: {
					title: 'Leads',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/blast/pages/Leads.tsx')).default }),
		},
		{
			path: 'report',
			index: true,
			id: 'blastReport',
			loader: () => ({
				meta: {
					title: 'Blast Report',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/blast/pages/Report')).default }),
		},
	],
};
export default route;
