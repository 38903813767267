import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'invoice',
	loader: () => ({
		meta: {
			title: 'Invoice',
			to: { id: 'invoice' },
			htmlTitlePattern: '#title :: Invoice',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/invoice/Layout')).default }),
	children: [
		{
			path: '',
			index: true,
			id: 'invoice',
			loader: () => ({
				meta: {
					title: 'List',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/invoice/pages/List')).default }),
		},
	],
};
export default route;
