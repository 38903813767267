import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'brm',
	id: 'brm',
	loader: () => ({
		meta: {
			title: 'BRM',
			to: { id: 'brmList' },
			htmlTitlePattern: '#title :: BRM',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/brm/Layout')).default }),
	children: [
		{
			path: 'brm',
			index: true,
			id: 'brmList',
			loader: () => ({
				meta: {
					title: 'List',
					disabled: true,
					htmlTitlePattern: 'BRM',
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/brm/pages/List')).default }),
		},
	],
};
export default route;
