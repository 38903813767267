import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes.tsx';

const route: RouteObject = {
	path: 'tools',
	lazy: async () => ({ Component: (await import('@/modules/tools/Layout')).default }),
	children: [
		{
			path: 'pdfMerger',
			index: true,
			id: 'pdfMergerTools',
			loader: () => ({
				meta: {
					title: 'PDF Merger',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/tools/pages/PdfMerger')).default }),
		},
	],
};
export default route;
