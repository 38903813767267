/*
 * Author S Brinta<brrinta@gmail.com>
 * Email: brrinta@gmail.com
 * Web: https://brinta.me
 * Created on : Wednesday 09 Nov, 2022 04:06:41 EST
 */

import { FieldTypes, SiteGroup } from 'shared/enums';
import { TemplateUserValue } from 'shared/types';

export { laborLawTemplateUserOptions } from '../modules/labor-law/consts';
export { annualReportTemplateUserOptions } from '../modules/annual-report/consts';
export { einEntityTypes } from '../modules/essentialDocuments/ein/consts';
export { fictitiousTemplateUserOptions } from '../modules/fictitious/consts';
export { propertyDocumentTemplateUserOptions } from '../modules/property-document/consts';
export { einTemplateUserOptions } from '../modules/essentialDocuments/ein/consts';
export { boiTemplateUserOptions } from '../modules/essentialDocuments/boi/consts';
export { certificateTemplateUserOptions } from '../modules/essentialDocuments/certificate/consts';
export { invoiceTemplateUserOptions } from '../modules/invoice/consts';
export { blastTemplateUserOptions } from '../modules/blast/consts';
export const Tables = {
	Attendances: 'Attendances',
	Blogs: 'Blogs',
	BRM: 'BRM',
	Banks: 'Banks',
	Deposits: 'Deposits',
	Expenses: 'Expenses',
	Invoices: 'Invoices',
	Jobs: 'Jobs',
	JobsSchedules: 'Jobs.Schedules',
	LogsTwilio: 'Logs.Twilio',
	LogsEmail: 'Logs.Email',
	NabePharmacyRequests: 'NabePharmacy.Requests',
	Notifications: 'Notifications',
	PaymentResponse: 'PaymentResponse',
	Payrolls: 'Payrolls',
	Products: 'Products',
	ProfilePayments: 'Profile.Payments',
	ProfileTwilio: 'Profile.Twilio',
	Settings: 'Settings',
	Tasks: 'Tasks',
	Terminals: 'Terminals',
	Users: 'Users',
	UserGroups: 'Users.Groups',
	Website: 'Website',
	Withdrawals: 'Withdrawals',
	Orders: 'Orders',
	Collections: 'Collections',
	DeclinedPayments: 'Declined.Payments',
	Subscriptions: 'Subscriptions',
	EmailTemplates: 'Templates.Email',
	Customers: 'Customers',
	Leads: 'Leads',
};
export const emailBcc: Record<SiteGroup | 'Task', Array<string>> = {
	[SiteGroup.AnnualReport]: ['info@champteks.com'],
	[SiteGroup.Certificate]: ['info@champteks.com'],
	[SiteGroup.Fictitious]: ['info@champteks.com'],
	[SiteGroup.PropertyDocument]: ['propertydocumentservices@gmail.com', 'info@champteks.com'],
	[SiteGroup.LaborLaw]: ['info@champteks.com'],
	[SiteGroup.EIN]: ['info@champteks.com'],
	[SiteGroup.YourKits]: ['info@champteks.com'],
	[SiteGroup.BOI]: ['info@champteks.com'],
	[SiteGroup.EssentialDocument]: ['info@champteks.com'],

	Task: ['info@champteks.com'],
};
export const YourKitsUserValues: Array<TemplateUserValue> = [
	{
		label: 'First Name',
		param: '{{firstName}}',
		path: 'firstName',
		type: FieldTypes.Text,
	},
	{
		label: 'Last Name',
		param: '{{lastName}}',
		path: 'lastName',
		type: FieldTypes.Text,
	},
	{
		label: 'Phone',
		param: '{{phone}}',
		path: 'phone',
		type: FieldTypes.Text,
	},
	{
		label: 'Email',
		param: '{{email}}',
		path: 'email',
		type: FieldTypes.Text,
	},
	{
		label: 'Address',
		param: '{{address}}',
		path: 'address',
		type: FieldTypes.Text,
	},
	{
		label: 'Apt/Suite',
		param: '{{address2}}',
		path: 'address2',
		type: FieldTypes.Text,
	},
	{
		label: 'City',
		param: '{{city}}',
		path: 'city',
		type: FieldTypes.Text,
	},
	{
		label: 'State',
		param: '{{state}}',
		path: 'state',
		type: FieldTypes.Text,
	},
	{
		label: 'Zip',
		param: '{{zip}}',
		path: 'zip',
		type: FieldTypes.Text,
	},
];

export const YourKitsColumns = [
	{
		ddc: false,
		db: 'firstName',
		default: 'FirstName',
		label: 'FirstName',
		type: FieldTypes.Text,
	},
	{
		ddc: false,
		db: 'lastName',
		default: 'LastName',
		label: 'LastName',
		type: FieldTypes.Text,
	},
	{
		ddc: true,
		db: 'phone',
		default: 'Phone',
		label: 'Phone',
		type: FieldTypes.Phone,
	},
	{
		ddc: false,
		db: 'email',
		default: 'Email',
		label: 'Email',
		type: FieldTypes.Email,
	},
	{
		db: 'insuranceNo',
		default: 'Insurance No',
		label: 'Insurance No',
		type: FieldTypes.Text,
		ddc: true,
	},
	{
		db: 'trackingNo',
		default: 'Tracking No',
		label: 'Tracking No',
		type: FieldTypes.Text,
		ddc: false,
	},
	{
		db: 'dateOfBirth',
		default: 'Birth Date',
		label: 'Birth Date',
		type: FieldTypes.Date,
		ddc: false,
	},
	{
		db: 'gender',
		default: 'Gender',
		label: 'Gender',
		type: FieldTypes.Text,
		ddc: false,
	},
	{
		db: 'address',
		default: 'Address',
		label: 'Address',
		type: FieldTypes.Text,
		ddc: false,
	},
	{
		db: 'address2',
		default: 'Apt/Suite',
		label: 'Apt/Suite',
		type: FieldTypes.Text,
		ddc: false,
	},
	{
		db: 'city',
		default: 'City',
		label: 'City',
		type: FieldTypes.Text,
		ddc: false,
	},
	{
		db: 'state',
		default: 'State',
		label: 'State',
		type: FieldTypes.Text,
		ddc: false,
	},
	{
		db: 'zip',
		default: 'Zip',
		label: 'Zip',
		type: FieldTypes.Text,
		ddc: false,
	},
	{
		db: 'orderDate',
		default: 'Create Date',
		label: 'Order Date',
		type: FieldTypes.Date,
		ddc: false,
	},
	{
		db: 'orderReference',
		default: 'Order Id',
		label: 'Order Ref ID',
		type: FieldTypes.Text,
		ddc: false,
	},
	{
		db: 'optInTime',
		default: 'Opt-In Time',
		label: 'Opt-In Time',
		type: FieldTypes.Date,
		ddc: false,
	},
];
export const FileBoiConstants = {
	companyTypeOptions: [
		{ label: 'Corporation', value: 'corporation' },
		{ label: 'LLC', value: 'llc' },
		{ label: 'LP', value: 'lp' },
		{ label: 'LLP', value: 'llp' },
		{ label: 'PA', value: 'pa' },
		{ label: 'PALLC', value: 'pallc' },
		{ label: 'Any other entity created by filing a document with the Secretary of State or similar office', value: 'any_other_entity' },
	],
	titleOptions: ['CEO', 'Executor', 'Managing Member', 'Managing Member/Owner', 'President', 'Other'],
	countries: [
		'United States',
		'Afghanistan',
		'Aland Islands',
		'Albania',
		'Algeria',
		'American Samoa',
		'Andorra',
		'Angola',
		'Anguilla',
		'Antarctica',
		'Antigua And Barbuda',
		'Argentina',
		'Armenia',
		'Aruba',
		'Australia',
		'Austria',
		'Azerbaijan',
		'Bahamas',
		'Bahrain',
		'Bangladesh',
		'Barbados',
		'Belarus',
		'Belgium',
		'Belize',
		'Benin',
		'Bermuda',
		'Bhutan',
		'Bolivia',
		'Bosnia And Herzegovina',
		'Botswana',
		'Bouvet Island',
		'Brazil',
		'British Indian Ocean Territory',
		'Brunei Darussalam',
		'Bulgaria',
		'Burkina Faso',
		'Burundi',
		'Cambodia',
		'Cameroon',
		'Canada',
		'Cape Verde',
		'Cayman Islands',
		'Central African Republic',
		'Chad',
		'Chile',
		'China',
		'Christmas Island',
		'Cocos (Keeling) Islands',
		'Colombia',
		'Comoros',
		'Congo',
		'Congo, Democratic Republic',
		'Cook Islands',
		'Costa Rica',
		"Cote D'Ivoire",
		'Croatia',
		'Cuba',
		'Cyprus',
		'Czech Republic',
		'Denmark',
		'Djibouti',
		'Dominica',
		'Dominican Republic',
		'Ecuador',
		'Egypt',
		'El Salvador',
		'Equatorial Guinea',
		'Eritrea',
		'Estonia',
		'Ethiopia',
		'Falkland Islands (Malvinas)',
		'Faroe Islands',
		'Fiji',
		'Finland',
		'France',
		'French Guiana',
		'French Polynesia',
		'French Southern Territories',
		'Gabon',
		'Gambia',
		'Georgia',
		'Germany',
		'Ghana',
		'Gibraltar',
		'Greece',
		'Greenland',
		'Grenada',
		'Guadeloupe',
		'Guam',
		'Guatemala',
		'Guernsey',
		'Guinea',
		'Guinea-Bissau',
		'Guyana',
		'Haiti',
		'Heard Island & Mcdonald Islands',
		'Holy See (Vatican City State)',
		'Honduras',
		'Hong Kong',
		'Hungary',
		'Iceland',
		'India',
		'Indonesia',
		'Iran, Islamic Republic Of',
		'Iraq',
		'Ireland',
		'Isle Of Man',
		'Israel',
		'Italy',
		'Jamaica',
		'Japan',
		'Jersey',
		'Jordan',
		'Kazakhstan',
		'Kenya',
		'Kiribati',
		'Korea',
		'Kuwait',
		'Kyrgyzstan',
		"Lao People's Democratic Republic",
		'Latvia',
		'Lebanon',
		'Lesotho',
		'Liberia',
		'Libyan Arab Jamahiriya',
		'Liechtenstein',
		'Lithuania',
		'Luxembourg',
		'Macao',
		'Macedonia',
		'Madagascar',
		'Malawi',
		'Malaysia',
		'Maldives',
		'Mali',
		'Malta',
		'Marshall Islands',
		'Martinique',
		'Mauritania',
		'Mauritius',
		'Mayotte',
		'Mexico',
		'Micronesia, Federated States Of',
		'Moldova',
		'Monaco',
		'Mongolia',
		'Montenegro',
		'Montserrat',
		'Morocco',
		'Mozambique',
		'Myanmar',
		'Namibia',
		'Nauru',
		'Nepal',
		'Netherlands',
		'Netherlands Antilles',
		'New Caledonia',
		'New Zealand',
		'Nicaragua',
		'Niger',
		'Nigeria',
		'Niue',
		'Norfolk Island',
		'Northern Mariana Islands',
		'Norway',
		'Oman',
		'Pakistan',
		'Palau',
		'Palestinian Territory, Occupied',
		'Panama',
		'Papua New Guinea',
		'Paraguay',
		'Peru',
		'Philippines',
		'Pitcairn',
		'Poland',
		'Portugal',
		'Puerto Rico',
		'Qatar',
		'Reunion',
		'Romania',
		'Russian Federation',
		'Rwanda',
		'Saint Barthelemy',
		'Saint Helena',
		'Saint Kitts And Nevis',
		'Saint Lucia',
		'Saint Martin',
		'Saint Pierre And Miquelon',
		'Saint Vincent And Grenadines',
		'Samoa',
		'San Marino',
		'Sao Tome And Principe',
		'Saudi Arabia',
		'Senegal',
		'Serbia',
		'Seychelles',
		'Sierra Leone',
		'Singapore',
		'Slovakia',
		'Slovenia',
		'Solomon Islands',
		'Somalia',
		'South Africa',
		'South Georgia And Sandwich Isl.',
		'Spain',
		'Sri Lanka',
		'Sudan',
		'Suriname',
		'Svalbard And Jan Mayen',
		'Swaziland',
		'Sweden',
		'Switzerland',
		'Syrian Arab Republic',
		'Taiwan',
		'Tajikistan',
		'Tanzania',
		'Thailand',
		'Timor-Leste',
		'Togo',
		'Tokelau',
		'Tonga',
		'Trinidad And Tobago',
		'Tunisia',
		'Turkey',
		'Turkmenistan',
		'Turks And Caicos Islands',
		'Tuvalu',
		'Uganda',
		'Ukraine',
		'United Arab Emirates',
		'United Kingdom',
		'United States Outlying Islands',
		'Uruguay',
		'Uzbekistan',
		'Vanuatu',
		'Venezuela',
		'Viet Nam',
		'Virgin Islands, British',
		'Virgin Islands, U.S.',
		'Wallis And Futuna',
		'Western Sahara',
		'Yemen',
		'Zambia',
		'Zimbabwe',
	],
	stateOptions: [
		'Alaska',
		'Alabama',
		'Arizona',
		'Arkansas',
		'California',
		'Colorado',
		'Connecticut',
		'Delaware',
		'District Of Columbia',
		'Florida',
		'Georgia',
		'Hawaii',
		'Idaho',
		'Illinois',
		'Indiana',
		'Iowa',
		'Kansas',
		'Kentucky',
		'Louisiana',
		'Maine',
		'Maryland',
		'Massachusetts',
		'Michigan',
		'Minnesota',
		'Mississippi',
		'Missouri',
		'Montana',
		'Nebraska',
		'Nevada',
		'New Hampshire',
		'New Jersey',
		'New Mexico',
		'New York',
		'North Carolina',
		'North Dakota',
		'Ohio',
		'Oklahoma',
		'Oregon',
		'Pennsylvania',
		'Rhode Island',
		'South Carolina',
		'South Dakota',
		'Tennessee',
		'Texas',
		'Utah',
		'Vermont',
		'Virginia',
		'Washington',
		'West Virginia',
		'Wisconsin',
		'Wyoming',
		'American Samoa',
		'Guam',
		'Marshall Islands',
		'Micronesia, Federal States',
		'Northern Mariana Islands',
		'Palau',
		'Puerto Rico',
		'U.S. Virgin Islands',
		'Armed Forces Europe/Middle-East/Canada',
		'Armed Forces Pacific',
		'Armed Forces Americas (except Canada)',
	],
	stateIsoCodes: [
		{ name: 'Alabama', code: 'AL' },
		{ name: 'Alaska', code: 'AK' },
		{ name: 'Arizona', code: 'AZ' },
		{ name: 'Arkansas', code: 'AR' },
		{ name: 'California', code: 'CA' },
		{ name: 'Colorado', code: 'CO' },
		{ name: 'Connecticut', code: 'CT' },
		{ name: 'Delaware', code: 'DE' },
		{ name: 'District of Columbia', code: 'DC' },
		{ name: 'Florida', code: 'FL' },
		{ name: 'Georgia', code: 'GA' },
		{ name: 'Hawaii', code: 'HI' },
		{ name: 'Idaho', code: 'ID' },
		{ name: 'Illinois', code: 'IL' },
		{ name: 'Indiana', code: 'IN' },
		{ name: 'Iowa', code: 'IA' },
		{ name: 'Kansa', code: 'KS' },
		{ name: 'Kentucky', code: 'KY' },
		{ name: 'Lousiana', code: 'LA' },
		{ name: 'Maine', code: 'ME' },
		{ name: 'Maryland', code: 'MD' },
		{ name: 'Massachusetts', code: 'MA' },
		{ name: 'Michigan', code: 'MI' },
		{ name: 'Minnesota', code: 'MN' },
		{ name: 'Mississippi', code: 'MS' },
		{ name: 'Missouri', code: 'MO' },
		{ name: 'Montana', code: 'MT' },
		{ name: 'Nebraska', code: 'NE' },
		{ name: 'Nevada', code: 'NV' },
		{ name: 'New Hampshire', code: 'NH' },
		{ name: 'New Jersey', code: 'NJ' },
		{ name: 'New Mexico', code: 'NM' },
		{ name: 'New York', code: 'NY' },
		{ name: 'North Carolina', code: 'NC' },
		{ name: 'North Dakota', code: 'ND' },
		{ name: 'Ohio', code: 'OH' },
		{ name: 'Oklahoma', code: 'OK' },
		{ name: 'Oregon', code: 'OR' },
		{ name: 'Pennsylvania', code: 'PA' },
		{ name: 'Rhode Island', code: 'RI' },
		{ name: 'South Carolina', code: 'SC' },
		{ name: 'South Dakota', code: 'SD' },
		{ name: 'Tennessee', code: 'TN' },
		{ name: 'Texas', code: 'TX' },
		{ name: 'Utah', code: 'UT' },
		{ name: 'Vermont', code: 'VT' },
		{ name: 'Virginia', code: 'VA' },
		{ name: 'Washington', code: 'WA' },
		{ name: 'West Virginia', code: 'WV' },
		{ name: 'Wisconsin', code: 'WI' },
		{ name: 'Wyoming', code: 'WY' },
	],
	jurisdictionOptions: [
		{ value: 'Absentee-Shawnee', label: 'Absentee-Shawnee Tribe of Indians of Oklahoma' },
		{ value: 'Agdaagux', label: 'Agdaagux Tribe of King Cove' },
		{ value: 'Agua Caliente', label: 'Agua Caliente Band of Cahuilla Indians of the Agua Caliente Indian Reservation, California' },
		{ value: 'Ak Chin', label: 'Ak-Chin Indian Community' },
		{ value: 'Akiachak', label: 'Akiachak Native Community' },
		{ value: 'Akiak', label: 'Akiak Native Community' },
		{ value: 'Alabama-Coushatta', label: 'Alabama-Coushatta Tribe of Texas' },
		{ value: 'Alabama-Quassarte Tribal Town', label: 'Alabama-Quassarte Tribal Town' },
		{ value: 'Alatna', label: 'Alatna Village' },
		{ value: 'Algaaciq', label: "Algaaciq Native Village (St. Mary's)" },
		{ value: 'Allakaket', label: 'Allakaket Village' },
		{ value: 'Alturas', label: 'Alturas Indian Rancheria, California' },
		{ value: 'Alutiiq Tribe of Old Harbor', label: 'Alutiiq Tribe of Old Harbor' },
		{ value: 'Angoon', label: 'Angoon Community Association' },
		{ value: 'Anvik', label: 'Anvik Village' },
		{ value: 'Apache', label: 'Apache Tribe of Oklahoma' },
		{ value: 'Arctic Village', label: 'Arctic Village (See Native Village of Venetie Tribal Government)' },
		{ value: "Asa'carsarmiut", label: "Asa'carsarmiut Tribe" },
		{ value: 'Assiniboine and Sioux', label: 'Assiniboine & Sioux Tribes of the Fort Peck Indian Reservation, Montana' },
		{ value: 'Augustine', label: 'Augustine Band of Cahuilla Indians, California' },
		{ value: 'Bad River Band', label: 'Bad River Band of the Lake Superior Tribe of Chippewa Indians of the Bad River Reservation, Wisconsin' },
		{ value: 'Bay Mills', label: 'Bay Mills Indian Community, Michigan' },
		{ value: 'Bear River', label: 'Bear River Band of the Rohnerville Rancheria, California' },
		{ value: 'Beaver', label: 'Beaver Village' },
		{ value: 'Berry Creek', label: 'Berry Creek Rancheria of Maidu Indians of California' },
		{ value: 'Big Lagoon', label: 'Big Lagoon Rancheria, California' },
		{ value: 'Big Pine Reservation', label: 'Big Pine Paiute Tribe of the Owens Valley' },
		{ value: 'Big Sandy', label: 'Big Sandy Rancheria of Western Mono Indians of California' },
		{ value: 'Big Valley Rancheria', label: 'Big Valley Band of Pomo Indians of the Big Valley Rancheria, California' },
		{ value: 'Birch Creek', label: 'Birch Creek Tribe' },
		{ value: 'Bishop Paiute', label: 'Bishop Paiute Tribe' },
		{ value: 'Blackfeet', label: 'Blackfeet Tribe of the Blackfeet Indian Reservation of Montana' },
		{ value: 'Blue Lake', label: 'Blue Lake Rancheria, California' },
		{ value: 'Bridgeport Indian Colony', label: 'Bridgeport Indian Colony' },
		{ value: 'Buena Vista Rancheria', label: 'Buena Vista Rancheria of Me-Wuk Indians of California' },
		{ value: 'Burns Paiute', label: 'Burns Paiute Tribe' },
		{ value: 'Cabazon', label: 'Cabazon Band of Mission Indians, California' },
		{ value: 'Cachil DeHe', label: 'Cachil DeHe Band of Wintun Indians of the Colusa Indian Community of the Colusa Rancheria, California' },
		{ value: 'Caddo', label: 'Caddo Nation of Oklahoma' },
		{ value: 'Cahto', label: 'Cahto Tribe of the Laytonville Rancheria' },
		{ value: 'Cahuilla', label: 'Cahuilla Band of Indians' },
		{ value: 'California Valley', label: 'California Valley Miwok Tribe, California' },
		{ value: 'Campo', label: 'Campo Band of Diegueno Mission Indians of the Campo Indian Reservation, California' },
		{
			value: 'Capitan Grande',
			label:
				'Capitan Grande Band of Diegueno Mission Indians of California (Barona Group of Capitan Grande Band of Mission Indians of the Barona Reservation, California)',
		},
		{ value: 'Capitan Grande Band', label: 'Capitan Grande Band of Diegueno Mission Indians of California' },
		{
			value: 'Viejas',
			label:
				'Capitan Grande Band of Diegueno Mission Indians of California: Viejas (Baron Long) Group of Capitan Grande Band of Mission	Indians of the	Viejas Reservation, California	',
		},
		{ value: 'Catawba', label: 'Catawba Indian Nation (aka Catawba Indian Tribe of South Carolina)' },
		{ value: 'Cayuga Nation of New York', label: 'Cayuga Nation' },
		{ value: 'Cedarville', label: 'Cedarville Rancheria, California' },
		{ value: 'Tlingit & Haida', label: 'Central Council of the Tlingit & Haida Indian Tribes' },
		{ value: 'Chalkyitsik', label: 'Chalkyitsik Village' },
		{ value: 'Cheesh-Na', label: 'Cheesh-Na Tribe' },
		{ value: 'Chemehuevi', label: 'Chemehuevi Indian Tribe of the Chemehuevi Reservation, California' },
		{ value: 'Cher-Ae Heights', label: 'Cher-Ae Heights Indian Community of the Trinidad Rancheria, California' },
		{ value: 'Cherokee Nation', label: 'Cherokee Nation' },
		{ value: 'Chevak', label: 'Chevak Native Village' },
		{ value: 'Cheyenne River', label: 'Cheyenne and Arapaho Tribes, Oklahoma' },
		{ value: 'Cheyenne River Sioux Tribe', label: 'Cheyenne River Sioux Tribe of the Cheyenne River Reservation, South Dakota' },
		{ value: 'Chickahominy Indians-Eastern Division', label: 'Chickahominy Indian Tribe - Eastern Division' },
		{ value: 'Chickahominy Indian Tribe, Inc.', label: 'Chickahominy Indian Tribe' },
		{ value: 'Chickaloon', label: 'Chickaloon Native Village' },
		{ value: 'Chicken Ranch', label: 'Chicken Ranch Rancheria of Me-Wuk Indians of California' },
		{ value: 'Chignik Bay', label: 'Chignik Bay Tribal Council' },
		{ value: 'Chignik Lake', label: 'Chignik Lake Village' },
		{ value: 'Chilkat', label: 'Chilkat Indian Village (Klukwan)' },
		{ value: 'Chilkoot', label: 'Chilkoot Indian Association (Haines)' },
		{ value: 'Chinik', label: 'Chinik Eskimo Community (Golovin)' },
		{ value: 'Chippewa-Cree', label: "Chippewa Cree Indians of the Rocky Boy's Reservation, Montana" },
		{ value: 'Chitimacha', label: 'Chitimacha Tribe of Louisiana' },
		{ value: 'Chuloonawick', label: 'Chuloonawick Native Village' },
		{ value: 'Circle', label: 'Circle Native Community' },
		{ value: 'Citizen Potawatomi', label: 'Citizen Potawatomi Nation, Oklahoma' },
		{ value: 'Cloverdale', label: 'Cloverdale Rancheria of Pomo Indians of California' },
		{ value: 'Cocopah', label: 'Cocopah Tribe of Arizona' },
		{ value: "Coeur D'Alene", label: "Coeur D'Alene Tribe" },
		{ value: 'Cold Springs', label: 'Cold Springs Rancheria of Mono Indians of California' },
		{ value: 'Colorado River', label: 'Colorado River Indian Tribes of the Colorado River Indian Reservation, Arizona and California' },
		{ value: 'Comanche', label: 'Comanche Nation, Oklahoma' },
		{ value: 'Confederated Salish', label: 'Confederated Salish and Kootenai Tribes of the Flathead Reservation' },
		{ value: 'Confederated Yakama', label: 'Confederated Tribes and Bands of the Yakama Nation' },
		{ value: 'Siletz Tribe', label: 'Confederated Tribes of Siletz Indians of Oregon' },
		{ value: 'Chehalis', label: 'Confederated Tribes of the Chehalis Reservation' },
		{ value: 'Confederated Colville', label: 'Confederated Tribes of the Colville Reservation' },
		{ value: 'Confederated Coos', label: 'Confederated Tribes of the Coos, Lower Umpqua and Siuslaw Indians' },
		{ value: 'Confederated Goshute', label: 'Confederated Tribes of the Goshute Reservation, Nevada and Utah' },
		{ value: 'Grand Ronde Tribes', label: 'Confederated Tribes of the Grand Ronde Community of Oregon' },
		{ value: 'Umatilla Tribe', label: 'Confederated Tribes of the Umatilla Indian Reservation' },
		{ value: 'Warms Springs Tribe', label: 'Confederated Tribes of the Warm Springs Reservation of Oregon' },
		{ value: 'Coquille', label: 'Coquille Indian Tribe' },
		{ value: 'Coushatta', label: 'Coushatta Tribe of Louisiana' },
		{ value: 'Cow Creek', label: 'Cow Creek Band of Umpqua Tribe of Indians' },
		{ value: 'Cowlitz', label: 'Cowlitz Indian Tribe' },
		{ value: 'Coyote Valley', label: 'Coyote Valley Band of Pomo Indians of California' },
		{ value: 'Craig', label: 'Craig Tribal Association' },
		{ value: 'Crow Creek', label: 'Crow Creek Sioux Tribe of the Crow Creek Reservation, South Dakota' },
		{ value: 'Crow', label: 'Crow Tribe of Montana' },
		{ value: 'Curyung', label: 'Curyung Tribal Council' },
		{ value: 'Delaware Nation', label: 'Delaware Nation, Oklahoma' },
		{ value: 'Delaware Tribe of Indians', label: 'Delaware Tribe of Indians' },
		{ value: 'Douglas', label: 'Douglas Indian Association' },
		{ value: 'Dry Creek', label: 'Dry Creek Rancheria Band of Pomo Indians, California' },
		{ value: 'Duckwater', label: 'Duckwater Shoshone Tribe of the Duckwater Reservation, Nevada' },
		{ value: 'Eastern Cherokee', label: 'Eastern Band of Cherokee Indians' },
		{ value: 'Eastern Shawnee Tribe of Oklahoma', label: 'Eastern Shawnee Tribe of Oklahoma' },
		{ value: 'Eastern Shoshone', label: 'Eastern Shoshone Tribe of the Wind River Reservation, Wyoming' },
		{ value: 'Egegik', label: 'Egegik Village' },
		{ value: 'Eklutna', label: 'Eklutna Native Village' },
		{ value: 'Elem', label: 'Elem Indian Colony of Pomo Indians of the Sulphur Bank Rancheria, California' },
		{ value: 'Elk Valley', label: 'Elk Valley Rancheria, California' },
		{ value: 'Ely Shoshone', label: 'Ely Shoshone Tribe of Nevada' },
		{ value: 'Emmonak', label: 'Emmonak Village' },
		{ value: 'Enterprise', label: 'Enterprise Rancheria of Maidu Indians of California' },
		{ value: 'Evansville', label: 'Evansville Village (aka Bettles Field)' },
		{ value: 'Ewiiaapaayp', label: 'Ewiiaapaayp Band of Kumeyaay Indians, California' },
		{ value: 'Federated Indians of Graton', label: 'Federated Indians of Graton Rancheria, California' },
		{ value: 'Flandreau', label: 'Flandreau Santee Sioux Tribe of South Dakota' },
		{ value: 'Forest County', label: 'Forest County Potawatomi Community, Wisconsin' },
		{ value: 'Assiniboine and Gros Ventre Tribes', label: 'Fort Belknap Indian Community of the Fort Belknap Reservation of Montana' },
		{ value: 'Fort Bidwell', label: 'Fort Bidwell Indian Community of the Fort Bidwell Reservation of California' },
		{ value: 'Fort Independence', label: 'Fort Independence Indian Community of Paiute Indians of the Fort Independence Reservation, California' },
		{ value: 'Fort McDermitt', label: 'Fort McDermitt Paiute and Shoshone Tribes of the Fort McDermitt Indian Reservation, Nevada and Oregon' },
		{ value: 'Fort McDowell', label: 'Fort McDowell Yavapai Nation, Arizona' },
		{ value: 'Fort Mojave', label: 'Fort Mojave Indian Tribe of Arizona, California & Nevada' },
		{ value: 'Fort Sill', label: 'Fort Sill Apache Tribe of Oklahoma' },
		{ value: 'Galena', label: 'Galena Village (aka Louden Village)' },
		{ value: 'Gila River', label: 'Gila River Indian Community of the Gila River Indian Reservation, Arizona' },
		{ value: 'Grand Traverse', label: 'Grand Traverse Band of Ottawa and Chippewa Indians, Michigan' },
		{ value: 'Greenville', label: 'Greenville Rancheria' },
		{ value: 'Grindstone', label: 'Grindstone Indian Rancheria of Wintun-Wailaki Indians of California' },
		{ value: 'Guidiville', label: 'Guidiville Rancheria of California' },
		{ value: 'Gulkana', label: 'Gulkana Village Council' },
		{ value: 'Habematolel', label: 'Habematolel Pomo of Upper Lake, California' },
		{ value: 'Hannahville', label: 'Hannahville Indian Community, Michigan' },
		{ value: 'Havasupai', label: 'Havasupai Tribe of the Havasupai Reservation, Arizona' },
		{ value: 'Healy Lake', label: 'Healy Lake Village' },
		{ value: 'Ho-Chunk', label: 'Ho-Chunk Nation of Wisconsin' },
		{ value: 'Hoh', label: 'Hoh Indian Tribe' },
		{ value: 'Holy Cross', label: 'Holy Cross Tribe' },
		{ value: 'Hoonah', label: 'Hoonah Indian Association' },
		{ value: 'Hoopa', label: 'Hoopa Valley Tribe, California' },
		{ value: 'Hopi', label: 'Hopi Tribe of Arizona' },
		{ value: 'Hopland', label: 'Hopland Band of Pomo Indians, California' },
		{ value: 'Houlton', label: 'Houlton Band of Maliseet Indians' },
		{ value: 'Hualapai', label: 'Hualapai Indian Tribe of the Hualapai Indian Reservation, Arizona' },
		{ value: 'Hughes', label: 'Hughes Village' },
		{ value: 'Huslia', label: 'Huslia Village' },
		{ value: 'Hydaburg', label: 'Hydaburg Cooperative Association' },
		{ value: 'Igiugig', label: 'Igiugig Village' },
		{ value: 'Iipay', label: 'Iipay Nation of Santa Ysabel, California' },
		{ value: 'Inaja', label: 'Inaja Band of Diegueno Mission Indians of the Inaja and Cosmit Reservation, California' },
		{ value: 'Inupiat Community of the Arctic Slope', label: 'Inupiat Community of the Arctic Slope' },
		{ value: 'Ione', label: 'Ione Band of Miwok Indians of California' },
		{ value: 'Iowa of Kansas', label: 'Iowa Tribe of Kansas and Nebraska' },
		{ value: 'Iowa of Oklahoma', label: 'Iowa Tribe of Oklahoma' },
		{ value: 'Iqugmiut', label: 'Iqugmiut Traditional Council' },
		{ value: 'Ivanof Bay Tribe', label: 'Ivanof Bay Tribe' },
		{ value: 'Jackson', label: 'Jackson Band of Miwuk Indians' },
		{ value: 'Jamestown', label: "Jamestown S'Klallam Tribe" },
		{ value: 'Jamul', label: 'Jamul Indian Village of California' },
		{ value: 'Jena', label: 'Jena Band of Choctaw Indians' },
		{ value: 'Jicarilla', label: 'Jicarilla Apache Nation, New Mexico' },
		{ value: 'Kaguyuk', label: 'Kaguyak Village' },
		{ value: 'Kaibab', label: 'Kaibab Band of Paiute Indians of the Kaibab Indian Reservation, Arizona' },
		{ value: 'Kaktovik', label: 'Kaktovik Village (aka Barter Island)' },
		{ value: 'Kalispel', label: 'Kalispel Indian Community of the Kalispel Reservation' },
		{ value: 'Karuk', label: 'Karuk Tribe' },
		{ value: 'Kashia', label: 'Kashia Band of Pomo Indians of the Stewarts Point Rancheria, California' },
		{ value: 'Kasigluk', label: 'Kasigluk Traditional Elders Council' },
		{ value: 'Kaw', label: 'Kaw Nation, Oklahoma' },
		{ value: 'Kenaitze', label: 'Kenaitze Indian Tribe' },
		{ value: 'Ketchikan', label: 'Ketchikan Indian Community' },
		{ value: 'Keweenaw', label: 'Keweenaw Bay Indian Community, Michigan' },
		{ value: 'Kialegee Tribal Town', label: 'Kialegee Tribal Town' },
		{ value: 'Kickapoo of Texas', label: 'Kickapoo Traditional Tribe of Texas' },
		{ value: 'Kickapoo Tribe in Kansas', label: 'Kickapoo Tribe of Indians of the Kickapoo Reservation in Kansas' },
		{ value: 'Kickapoo of Oklahoma', label: 'Kickapoo Tribe of Oklahoma' },
		{ value: 'King Island', label: 'King Island Native Community' },
		{ value: 'King Salmon', label: 'King Salmon Tribe' },
		{ value: 'Kiowa Tribe', label: 'Kiowa Indian Tribe of Oklahoma' },
		{ value: 'Klamath', label: 'Klamath Tribes' },
		{ value: 'Klawock', label: 'Klawock Cooperative Association' },
		{ value: 'Cortina', label: 'Kletsel Dehe Band of Wintun Indians' },
		{ value: 'Knik', label: 'Knik Tribe' },
		{ value: 'Koi', label: 'Koi Nation of Northern California' },
		{ value: 'Kokhanok', label: 'Kokhanok Village' },
		{ value: 'Kootenai', label: 'Kootenai Tribe of Idaho' },
		{ value: 'Koyukuk', label: 'Koyukuk Native Village' },
		{ value: 'La Jolla', label: 'La Jolla Band of Luiseno Indians, California' },
		{ value: 'La Posta', label: 'La Posta Band of Diegueno Mission Indians of the La Posta Indian Reservation, California' },
		{ value: 'Lac Courte Oreilles', label: 'Lac Courte Oreilles Band of Lake Superior Chippewa Indians of Wisconsin' },
		{ value: 'Lac du Flambeau', label: 'Lac du Flambeau Band of Lake Superior Chippewa Indians of the Lac du Flambeau Reservation of Wisconsin' },
		{ value: 'Lac Vieux', label: 'Lac Vieux Desert Band of Lake Superior Chippewa Indians of Michigan' },
		{ value: 'Las Vegas', label: 'Las Vegas Tribe of Paiute Indians of the Las Vegas Indian Colony, Nevada' },
		{ value: 'Levelock', label: 'Levelock Village' },
		{ value: 'Lime', label: 'Lime Village' },
		{ value: 'Little River', label: 'Little River Band of Ottawa Indians, Michigan' },
		{ value: 'Little Shell Tribe', label: 'Little Shell Tribe of Chippewa Indians of Montana' },
		{ value: 'Little Traverse', label: 'Little Traverse Bay Bands of Odawa Indians, Michigan' },
		{ value: 'Lone Pine', label: 'Lone Pine Paiute-Shoshone Tribe' },
		{ value: 'Los Coyotes', label: 'Los Coyotes Band of Cahuilla and Cupeno Indians, California' },
		{ value: 'Lovelock', label: 'Lovelock Paiute Tribe of the Lovelock Indian Colony, Nevada' },
		{ value: 'Lower Brule', label: 'Lower Brule Sioux Tribe of the Lower Brule Reservation, South Dakota' },
		{ value: 'Lower Elwha', label: 'Lower Elwha Tribal Community' },
		{ value: 'Lower Sioux', label: 'Lower Sioux Indian Community in the State of Minnesota' },
		{ value: 'Lummi', label: 'Lummi Tribe of the Lummi Reservation' },
		{ value: 'Lytton', label: 'Lytton Rancheria of California' },
		{ value: 'Makah', label: 'Makah Indian Tribe of the Makah Indian Reservation' },
		{ value: 'Manchester', label: 'Manchester Band of Pomo Indians of the Manchester Rancheria, California' },
		{ value: 'Manley Hot Springs', label: 'Manley Hot Springs Village' },
		{ value: 'Manokotak', label: 'Manokotak Village' },
		{ value: 'Manzanita', label: 'Manzanita Band of Diegueno Mission Indians of the Manzanita Reservation, California' },
		{ value: 'Mashantucket Pequot', label: 'Mashantucket Pequot Indian Tribe' },
		{ value: 'Mashpee', label: 'Mashpee Wampanoag Tribe' },
		{ value: 'Match-e-be-nash-she-wish Band', label: 'Match-e-be-nash-she-wish Band of Pottawatomi Indians of Michigan' },
		{ value: 'Mcgrath', label: 'McGrath Native Village' },
		{ value: 'Mechoopda', label: 'Mechoopda Indian Tribe of Chico Rancheria, California' },
		{ value: 'Menominee', label: 'Menominee Indian Tribe of Wisconsin' },
		{ value: 'Mentasta', label: 'Mentasta Traditional Council' },
		{ value: 'Mesa Grande', label: 'Mesa Grande Band of Diegueno Mission Indians of the Mesa Grande Reservation, California' },
		{ value: 'Mescalero Apache', label: 'Mescalero Apache Tribe of the Mescalero Reservation, New Mexico' },
		{ value: 'Metlakatla', label: 'Metlakatla Indian Community, Annette Island Reserve' },
		{ value: 'Miami Tribe of Oklahoma', label: 'Miami Tribe of Oklahoma' },
		{ value: 'Miccosukee', label: 'Miccosukee Tribe of Indians' },
		{ value: 'Middletown', label: 'Middletown Rancheria of Pomo Indians of California' },
		{ value: "Mi'kmaq Nation", label: "Mi'kmaq Nation" },
		{ value: 'Bois Forte', label: 'Minnesota Chippewa Tribe - Bois Forte Band (Nett Lake)' },
		{ value: 'Fond du Lac', label: 'Minnesota Chippewa Tribe - Fond du Lac Band' },
		{ value: 'Grand Portage', label: 'Minnesota Chippewa Tribe - Grand Portage Band' },
		{ value: 'Leech Lake', label: 'Minnesota Chippewa Tribe - Leech Lake Band' },
		{ value: 'Mille Lacs', label: 'Minnesota Chippewa Tribe - Mille Lacs Band' },
		{ value: 'White Earth', label: 'Minnesota Chippewa Tribe - White Earth Band' },
		{ value: 'Minnesota Chippewa', label: 'Minnesota Chippewa Tribe, Minnesota' },
		{ value: 'Mississippi Choctaw', label: 'Mississippi Band of Choctaw Indians' },
		{ value: 'Moapa', label: 'Moapa Band of Paiute Indians of the Moapa River Indian Reservation, Nevada' },
		{ value: 'Modoc Nation', label: 'Modoc Nation' },
		{ value: 'Mohegan', label: 'Mohegan Tribe of Indians of Connecticut' },
		{ value: 'Monacan Indian Nation', label: 'Monacan Indian Nation' },
		{ value: 'Mooretown', label: 'Mooretown Rancheria of Maidu Indians of California' },
		{ value: 'Morongo', label: 'Morongo Band of Mission Indians, California' },
		{ value: 'Muckleshoot', label: 'Muckleshoot Indian Tribe' },
		{ value: 'Naknek', label: 'Naknek Native Village' },
		{ value: 'Nansemond Indian Tribe', label: 'Nansemond Indian Nation' },
		{ value: 'Narragansett', label: 'Narragansett Indian Tribe' },
		{ value: 'Afognak', label: 'Native Village of Afognak' },
		{ value: 'Akhiok', label: 'Native Village of Akhiok' },
		{ value: 'Akutan', label: 'Native Village of Akutan' },
		{ value: 'Aleknagik', label: 'Native Village of Aleknagik' },
		{ value: 'Ambler', label: 'Native Village of Ambler' },
		{ value: 'Atka', label: 'Native Village of Atka' },
		{ value: 'Native Village of Atqasuk', label: 'Native Village of Atqasuk' },
		{ value: 'Barrow', label: 'Native Village of Barrow Inupiat Traditional Government' },
		{ value: 'Belkofski', label: 'Native Village of Belkofski' },
		{ value: 'Brevig Mission', label: 'Native Village of Brevig Mission' },
		{ value: 'Buckland', label: 'Native Village of Buckland' },
		{ value: 'Cantwell', label: 'Native Village of Cantwell' },
		{ value: 'Chenega', label: 'Native Village of Chenega (aka Chanega)' },
		{ value: 'Chignik Lagoon', label: 'Native Village of Chignik Lagoon' },
		{ value: 'Chitina', label: 'Native Village of Chitina' },
		{ value: 'Chuathbaluk', label: 'Native Village of Chuathbaluk (Russian Mission, Kuskokwim)' },
		{ value: 'Council', label: 'Native Village of Council' },
		{ value: 'Deering', label: 'Native Village of Deering' },
		{ value: 'Diomede', label: 'Native Village of Diomede (aka Inalik)' },
		{ value: 'Eagle', label: 'Native Village of Eagle' },
		{ value: 'Eek', label: 'Native Village of Eek' },
		{ value: 'Ekuk', label: 'Native Village of Ekuk' },
		{ value: 'Ekwok', label: 'Native Village of Ekwok' },
		{ value: 'Elim IRA', label: 'Native Village of Elim' },
		{ value: 'Eyak', label: 'Native Village of Eyak (Cordova)' },
		{ value: 'False Pass', label: 'Native Village of False Pass' },
		{ value: 'Fort Yukon', label: 'Native Village of Fort Yukon' },
		{ value: 'Gakona', label: 'Native Village of Gakona' },
		{ value: 'Gambell', label: 'Native Village of Gambell' },
		{ value: 'Georgetown', label: 'Native Village of Georgetown' },
		{ value: 'Goodnews Bay', label: 'Native Village of Goodnews Bay' },
		{ value: 'Hamilton', label: 'Native Village of Hamilton' },
		{ value: 'Hooper Bay', label: 'Native Village of Hooper Bay' },
		{ value: 'Kanatak', label: 'Native Village of Kanatak' },
		{ value: 'Karluk', label: 'Native Village of Karluk' },
		{ value: 'Kiana', label: 'Native Village of Kiana' },
		{ value: 'Kipnuk', label: 'Native Village of Kipnuk' },
		{ value: 'Kivalina', label: 'Native Village of Kivalina' },
		{ value: 'Kluti Kaah', label: 'Native Village of Kluti Kaah (aka Copper Center)' },
		{ value: 'Kobuk', label: 'Native Village of Kobuk' },
		{ value: 'Kongiganak', label: 'Native Village of Kongiganak' },
		{ value: 'Kotzebue', label: 'Native Village of Kotzebue' },
		{ value: 'Koyuk', label: 'Native Village of Koyuk' },
		{ value: 'Kwigillingok', label: 'Native Village of Kwigillingok' },
		{ value: 'Kwinhagak', label: 'Native Village of Kwinhagak (aka Quinhagak)' },
		{ value: 'Larsen Bay', label: 'Native Village of Larsen Bay' },
		{ value: 'Marshall', label: 'Native Village of Marshall (aka Fortuna Ledge)' },
		{ value: "Mary's Igloo", label: "Native Village of Mary's Igloo" },
		{ value: 'Mekoryuk', label: 'Native Village of Mekoryuk' },
		{ value: 'Minto', label: 'Native Village of Minto' },
		{ value: 'Nanwalek', label: 'Native Village of Nanwalek (aka English Bay)' },
		{ value: 'Napaimute', label: 'Native Village of Napaimute' },
		{ value: 'Napakiak', label: 'Native Village of Napakiak' },
		{ value: 'Napaskiak', label: 'Native Village of Napaskiak' },
		{ value: 'Nelson Lagoon', label: 'Native Village of Nelson Lagoon' },
		{ value: 'Nightmute', label: 'Native Village of Nightmute' },
		{ value: 'Nikolski', label: 'Native Village of Nikolski' },
		{ value: 'Noatak', label: 'Native Village of Noatak' },
		{ value: 'Nuiqsut', label: 'Native Village of Nuiqsut (aka Nooiksut)' },
		{ value: 'Nunam Iqua', label: 'Native Village of Nunam Iqua' },
		{ value: 'Nunapitchuk', label: 'Native Village of Nunapitchuk' },
		{ value: 'Ouzinkie', label: 'Native Village of Ouzinkie' },
		{ value: 'Paimiut', label: 'Native Village of Paimiut' },
		{ value: 'Perryville', label: 'Native Village of Perryville' },
		{ value: 'Pilot Point', label: 'Native Village of Pilot Point' },
		{ value: 'Point Hope IRA', label: 'Native Village of Point Hope' },
		{ value: 'Point Lay IRA', label: 'Native Village of Point Lay' },
		{ value: 'Port Graham', label: 'Native Village of Port Graham' },
		{ value: 'Port Heiden', label: 'Native Village of Port Heiden' },
		{ value: 'Port Lions', label: 'Native Village of Port Lions' },
		{ value: 'Ruby', label: 'Native Village of Ruby' },
		{ value: 'St. Michael IRA', label: 'Native Village of Saint Michael' },
		{ value: 'Savoonga', label: 'Native Village of Savoonga' },
		{ value: 'Scammon Bay', label: 'Native Village of Scammon Bay' },
		{ value: 'Selawik', label: 'Native Village of Selawik' },
		{ value: 'Shaktoolik', label: 'Native Village of Shaktoolik' },
		{ value: 'Shishmaref IRA', label: 'Native Village of Shishmaref' },
		{ value: 'Shungnak', label: 'Native Village of Shungnak' },
		{ value: 'Stevens Village', label: 'Native Village of Stevens' },
		{ value: 'Tanacross', label: 'Native Village of Tanacross' },
		{ value: 'Tanana', label: 'Native Village of Tanana' },
		{ value: 'Tatitlek', label: 'Native Village of Tatitlek' },
		{ value: 'Tazlina', label: 'Native Village of Tazlina' },
		{ value: 'Teller', label: 'Native Village of Teller' },
		{ value: 'Tetlin', label: 'Native Village of Tetlin' },
		{ value: 'Tuntutuliak', label: 'Native Village of Tuntutuliak' },
		{ value: 'Tununak', label: 'Native Village of Tununak' },
		{ value: 'Tyonek', label: 'Native Village of Tyonek' },
		{ value: 'Unalakleet', label: 'Native Village of Unalakleet' },
		{ value: 'Unga', label: 'Native Village of Unga' },
		{ value: 'Venetie IRA', label: 'Native Village of Venetie Tribal Government (Arctic Village and Village of Venetie)' },
		{ value: 'Wales', label: 'Native Village of Wales' },
		{ value: 'White Mountain AK', label: 'Native Village of White Mountain' },
		{ value: 'Navajo', label: 'Navajo Nation, Arizona, New Mexico & Utah' },
		{ value: 'Nenana', label: 'Nenana Native Association' },
		{ value: 'New Koliganek', label: 'New Koliganek Village Council' },
		{ value: 'New Stuyahok', label: 'New Stuyahok Village' },
		{ value: 'Newhalen', label: 'Newhalen Village' },
		{ value: 'Newtok', label: 'Newtok Village' },
		{ value: 'Nez Perce', label: 'Nez Perce Tribe' },
		{ value: 'Nikolai', label: 'Nikolai Village' },
		{ value: 'Ninilchik', label: 'Ninilchik Village' },
		{ value: 'Nisqually', label: 'Nisqually Indian Tribe' },
		{ value: 'Nome', label: 'Nome Eskimo Community' },
		{ value: 'Nondalton', label: 'Nondalton Village' },
		{ value: 'Nooksack', label: 'Nooksack Indian Tribe' },
		{ value: 'Noorvik', label: 'Noorvik Native Community' },
		{ value: 'Northern Arapaho', label: 'Northern Arapaho Tribe of the Wind River Reservation, Wyoming' },
		{ value: 'Northern Cheyenne', label: 'Northern Cheyenne Tribe of the Northern Cheyenne Indian Reservation, Montana' },
		{ value: 'North Fork', label: 'Northfork Rancheria of Mono Indians of California' },
		{ value: 'Northway', label: 'Northway Village' },
		{ value: 'Northwestern Shoshone', label: 'Northwestern Band of the Shoshone Nation' },
		{ value: 'Nottawaseppi Potawatomi', label: 'Nottawaseppi Huron Band of the Potawatomi, Michigan' },
		{ value: 'Nulato', label: 'Nulato Village' },
		{ value: 'Nunakauyarmiut', label: 'Nunakauyarmiut Tribe' },
		{ value: 'Oglala Sioux', label: 'Oglala Sioux Tribe' },
		{ value: 'Ohkay Owingeh', label: 'Ohkay Owingeh, New Mexico' },
		{ value: 'Omaha', label: 'Omaha Tribe of Nebraska' },
		{ value: 'Oneida', label: 'Oneida Indian Nation' },
		{ value: 'Oneida Nation (Wisconsin)', label: 'Oneida Nation' },
		{ value: 'Onondaga', label: 'Onondaga Nation' },
		{ value: 'Grayling', label: 'Organized Village of Grayling (aka Holikachuk)' },
		{ value: 'Kake', label: 'Organized Village of Kake' },
		{ value: 'Kasaan', label: 'Organized Village of Kasaan' },
		{ value: 'Kwethluk', label: 'Organized Village of Kwethluk' },
		{ value: 'Saxman', label: 'Organized Village of Saxman' },
		{ value: 'Orutsararmiut', label: 'Orutsararmiut Traditional Native Council' },
		{ value: 'Oscarville', label: 'Oscarville Traditional Village' },
		{ value: 'Otoe-Missouria', label: 'Otoe-Missouria Tribe of Indians, Oklahoma' },
		{ value: 'Ottawa Tribe of Oklahoma', label: 'Ottawa Tribe of Oklahoma' },
		{
			value: 'Paiute of Utah',
			label:
				'Paiute Indian Tribe of Utah (Cedar Band of Paiutes, Kanosh Band of Paiutes, Koosharem Band of Paiutes, Indian Peaks Band of Paiutes, and Shivwits Band of Paiutes) ',
		},
		{ value: 'Paiute-Shoshone', label: 'Paiute-Shoshone Tribe of the Fallon Reservation and Colony, Nevada' },
		{ value: 'Pala', label: 'Pala Band of Mission Indians' },
		{ value: 'Pamunkey Indian Tribe', label: 'Pamunkey Indian Tribe' },
		{ value: 'Pascua Yaqui', label: 'Pascua Yaqui Tribe of Arizona' },
		{ value: 'Paskenta', label: 'Paskenta Band of Nomlaki Indians of California' },
		{ value: 'Passamaquoddy Indian Township', label: 'Passamaquoddy Tribe - Indian Township' },
		{ value: 'Passamaquoddy Pleasant Point', label: 'Passamaquoddy Tribe - Pleasant Point' },
		{ value: 'Passamaquoddy Tribe', label: 'Passamaquoddy Tribe' },
		{ value: 'Pauloff Harbor', label: 'Pauloff Harbor Village' },
		{ value: 'Pauma', label: 'Pauma Band of Luiseno Mission Indians of the Pauma & Yuima Reservation, California' },
		{ value: 'Pawnee', label: 'Pawnee Nation of Oklahoma' },
		{ value: 'Pechanga Band of Indians', label: 'Pechanga Band of Indians' },
		{ value: 'Pedro Bay', label: 'Pedro Bay Village' },
		{ value: 'Penobscot', label: 'Penobscot Nation' },
		{ value: 'Peoria Tribe of Indians of Oklahoma', label: 'Peoria Tribe of Indians of Oklahoma' },
		{ value: 'Petersburg', label: 'Petersburg Indian Association' },
		{ value: 'Picayune', label: 'Picayune Rancheria of Chukchansi Indians of California' },
		{ value: 'Pilot Station', label: 'Pilot Station Traditional Village' },
		{ value: 'Pinoleville', label: 'Pinoleville Pomo Nation, California' },
		{ value: 'Pit River', label: 'Pit River Tribe, California' },
		{ value: "Pitka's Point", label: "Pitka's Point Traditional Council" },
		{ value: 'Platinum', label: 'Platinum Traditional Village' },
		{ value: 'Poarch', label: 'Poarch Band of Creek Indians' },
		{ value: 'Pokagon', label: 'Pokagon Band of Potawatomi Indians, Michigan and Indiana' },
		{ value: 'Ponca of Oklahoma', label: 'Ponca Tribe of Indians of Oklahoma' },
		{ value: 'Ponca of Nebraska', label: 'Ponca Tribe of Nebraska' },
		{ value: 'Port Gamble', label: "Port Gamble S'Klallam Tribe" },
		{ value: 'Portage Creek', label: 'Portage Creek Village (aka Ohgsenakale)' },
		{ value: 'Potter Valley', label: 'Potter Valley Tribe, California' },
		{ value: 'Prairie Band', label: 'Prairie Band Potawatomi Nation' },
		{ value: 'Prairie Island', label: 'Prairie Island Indian Community in the State of Minnesota' },
		{ value: 'Pribilof Islands Aleut Communities', label: 'Pribilof Islands Aleut Communities' },
		{ value: 'Pueblo of Acoma', label: 'Pueblo of Acoma, New Mexico' },
		{ value: 'Pueblo of Cochiti', label: 'Pueblo of Cochiti, New Mexico' },
		{ value: 'Pueblo of Isleta', label: 'Pueblo of Isleta, New Mexico' },
		{ value: 'Pueblo of Jemez', label: 'Pueblo of Jemez, New Mexico' },
		{ value: 'Pueblo of Laguna', label: 'Pueblo of Laguna, New Mexico' },
		{ value: 'Pueblo of Nambe', label: 'Pueblo of Nambe, New Mexico' },
		{ value: 'Pueblo of Picuris', label: 'Pueblo of Picuris, New Mexico' },
		{ value: 'Pueblo of Pojoaque', label: 'Pueblo of Pojoaque, New Mexico' },
		{ value: 'Pueblo of San Felipe', label: 'Pueblo of San Felipe, New Mexico' },
		{ value: 'Pueblo of San Ildefonso', label: 'Pueblo of San Ildefonso, New Mexico' },
		{ value: 'Pueblo of Sandia', label: 'Pueblo of Sandia, New Mexico' },
		{ value: 'Pueblo of Santa Ana', label: 'Pueblo of Santa Ana, New Mexico' },
		{ value: 'Pueblo of Santa Clara', label: 'Pueblo of Santa Clara, New Mexico' },
		{ value: 'Pueblo of Taos', label: 'Pueblo of Taos, New Mexico' },
		{ value: 'Pueblo of Tesuque', label: 'Pueblo of Tesuque, New Mexico' },
		{ value: 'Pueblo of Zia', label: 'Pueblo of Zia, New Mexico' },
		{ value: 'Puyallup', label: 'Puyallup Tribe of the Puyallup Reservation' },
		{ value: 'Pyramid Lake', label: 'Pyramid Lake Paiute Tribe of the Pyramid Lake Reservation, Nevada' },
		{ value: 'Qagan Tayagungin', label: 'Qagan Tayagungin Tribe of Sand Point' },
		{ value: 'Qawalangin', label: 'Qawalangin Tribe of Unalaska' },
		{ value: 'Quapaw Nation', label: 'Quapaw Nation' },
		{ value: 'Quartz Valley', label: 'Quartz Valley Indian Community of the Quartz Valley Reservation of California' },
		{ value: 'Quechan', label: 'Quechan Tribe of the Fort Yuma Indian Reservation, California & Arizona' },
		{ value: 'Quileute', label: 'Quileute Tribe of the Quileute Reservation' },
		{ value: 'Quinault', label: 'Quinault Indian Nation' },
		{ value: 'Ramah Navajo Chapter', label: 'Ramah Navajo Chapter of the Navajo Nation' },
		{ value: 'Ramona', label: 'Ramona Band of Cahuilla, California' },
		{ value: 'Rampart', label: 'Rampart Village' },
		{ value: 'Rappahannock Tribe, Inc.', label: 'Rappahannock Tribe, Inc.' },
		{ value: 'Red Cliff', label: 'Red Cliff Band of Lake Superior Chippewa Indians of Wisconsin' },
		{ value: 'Red Lake', label: 'Red Lake Band of Chippewa Indians, Minnesota' },
		{ value: 'Redding', label: 'Redding Rancheria, California' },
		{ value: 'Redwood Valley', label: 'Redwood Valley or Little River Band of Pomo Indians of the Redwood Valley Rancheria California' },
		{ value: 'Reno-Sparks', label: 'Reno-Sparks Indian Colony, Nevada' },
		{ value: 'Resighini', label: 'Resighini Rancheria, California' },
		{ value: 'Rincon', label: 'Rincon Band of Luiseno Mission Indians of the Rincon Reservation, California' },
		{ value: 'Robinson', label: 'Robinson Rancheria' },
		{ value: 'Rosebud', label: 'Rosebud Sioux Tribe of the Rosebud Indian Reservation, South Dakota' },
		{ value: 'Round Valley', label: 'Round Valley Indian Tribes, Round Valley Reservation, California' },
		{ value: 'Sac & Fox Nation of Missouri in Kansas and Nebraska', label: 'Sac & Fox Nation of Missouri in Kansas and Nebraska' },
		{ value: 'Sac & Fox Nation, Oklahoma', label: 'Sac & Fox Nation, Oklahoma' },
		{ value: 'Sac & Fox of Mississippi', label: 'Sac & Fox Tribe of the Mississippi in Iowa' },
		{ value: 'Saginaw Chippewa', label: 'Saginaw Chippewa Indian Tribe of Michigan' },
		{ value: 'St. George', label: 'Saint George Island (See Pribilof Islands Aleut Communities of St. Paul & St. George Islands)' },
		{ value: 'Saint Paul', label: 'Saint Paul Island (See Pribilof Islands Aleut Communities of St. Paul & St. George Islands)' },
		{ value: 'Saint Regis', label: 'Saint Regis Mohawk Tribe' },
		{ value: 'Salamatof', label: 'Salamatof Tribe' },
		{ value: 'Salt River', label: 'Salt River Pima-Maricopa Indian Community of the Salt River Reservation, Arizona' },
		{ value: 'Samish', label: 'Samish Indian Nation' },
		{ value: 'San Carlos', label: 'San Carlos Apache Tribe of the San Carlos Reservation, Arizona' },
		{ value: 'San Juan', label: 'San Juan Southern Paiute Tribe of Arizona' },
		{ value: 'San Pasqual', label: 'San Pasqual Band of Diegueno Mission Indians of California' },
		{ value: 'Santa Rosa of Cahuilla', label: 'Santa Rosa Band of Cahuilla Indians, California' },
		{ value: 'Santa Rosa', label: 'Santa Rosa Indian Community of the Santa Rosa Rancheria, California' },
		{ value: 'Santa Ynez', label: 'Santa Ynez Band of Chumash Mission Indians of the Santa Ynez Reservation, California' },
		{ value: 'Santee Sioux', label: 'Santee Sioux Nation, Nebraska' },
		{ value: 'Santo Domingo', label: 'Santo Domingo Pueblo' },
		{ value: 'Sauk-Suiattle', label: 'Sauk-Suiattle Indian Tribe' },
		{ value: 'Sault Ste. Marie', label: 'Sault Ste. Marie Tribe of Chippewa Indians, Michigan' },
		{ value: 'Scotts Valley', label: 'Scotts Valley Band of Pomo Indians of California' },
		{ value: 'Seldovia', label: 'Seldovia Village Tribe' },
		{ value: 'Seminole', label: 'Seminole Tribe of Florida' },
		{ value: 'Seneca', label: 'Seneca Nation of Indians' },
		{ value: 'Seneca-Cayuga Nation', label: 'Seneca-Cayuga Nation' },
		{ value: 'Shageluk', label: 'Shageluk Native Village' },
		{ value: 'Shakopee', label: 'Shakopee Mdewakanton Sioux Community of Minnesota' },
		{ value: 'Shawnee Tribe', label: 'Shawnee Tribe' },
		{ value: 'Sherwood Valley', label: 'Sherwood Valley Rancheria of Pomo Indians of California' },
		{ value: 'Shingle Springs', label: 'Shingle Springs Band of Miwok Indians, Shingle Springs Rancheria (Verona Tract), California' },
		{ value: 'Shinnecock', label: 'Shinnecock Indian Nation' },
		{ value: 'Shoalwater', label: 'Shoalwater Bay Indian Tribe of the Shoalwater Bay Indian Reservation' },
		{ value: 'Shoshone-Bannock', label: 'Shoshone-Bannock Tribes of the Fort Hall Reservation' },
		{ value: 'Shoshone-Paiute', label: 'Shoshone-Paiute Tribes of the Duck Valley Reservation, Nevada' },
		{ value: 'Sisseton-Wahpeton', label: 'Sisseton-Wahpeton Oyate of the Lake Traverse Reservation, South Dakota' },
		{ value: 'Sitka', label: 'Sitka Tribe of Alaska' },
		{ value: 'Skagway', label: 'Skagway Village' },
		{ value: 'Skokomish', label: 'Skokomish Indian Tribe' },
		{ value: 'Skull Valley', label: 'Skull Valley Band of Goshute Indians of Utah' },
		{ value: 'Snoqualmie', label: 'Snoqualmie Indian Tribe' },
		{ value: 'Soboba', label: 'Soboba Band of Luiseno Indians, California' },
		{ value: 'Sokaogon', label: 'Sokaogon Chippewa Community, Wisconsin' },
		{ value: 'South Naknek', label: 'South Naknek Village' },
		{ value: 'Southern Ute', label: 'Southern Ute Indian Tribe of the Southern Ute Reservation, Colorado' },
		{ value: 'Spirit Lake', label: 'Spirit Lake Tribe, North Dakota' },
		{ value: 'Spokane', label: 'Spokane Tribe of the Spokane Reservation' },
		{ value: 'Squaxin', label: 'Squaxin Island Tribe of the Squaxin Island Reservation' },
		{ value: 'St. Croix', label: 'St. Croix Chippewa Indians of Wisconsin' },
		{ value: 'Standing Rock', label: 'Standing Rock Sioux Tribe of North & South Dakota' },
		{ value: 'Stebbins', label: 'Stebbins Community Association' },
		{ value: 'Stillaguamish', label: 'Stillaguamish Tribe of Indians of Washington' },
		{ value: 'Stockbridge', label: 'Stockbridge Munsee Community, Wisconsin' },
		{ value: 'Summit Lake', label: 'Summit Lake Paiute Tribe of Nevada' },
		{ value: "Sun'aq", label: "Sun'aq Tribe of Kodiak" },
		{ value: 'Suquamish', label: 'Suquamish Indian Tribe of the Port Madison Reservation' },
		{ value: 'Susanville', label: 'Susanville Indian Rancheria, California' },
		{ value: 'Swinomish', label: 'Swinomish Indian Tribal Community' },
		{ value: 'Sycuan', label: 'Sycuan Band of the Kumeyaay Nation' },
		{ value: 'Table Mountain', label: 'Table Mountain Rancheria' },
		{ value: 'Takotna', label: 'Takotna Village' },
		{ value: 'Tangirnaq', label: 'Tangirnaq Native Village' },
		{ value: 'Tejon', label: 'Tejon Indian Tribe' },
		{ value: 'Telida', label: 'Telida Village' },
		{
			value: 'Te-Moak',
			label:
				'Te-Moak Tribe of Western Shoshone Indians of Nevada (Four constituent bands: Battle Mountain Band; Elko Band; South Fork Band and Wells Band) ',
		},
		{ value: 'Chickasaw Nation', label: 'The Chickasaw Nation' },
		{ value: 'The Choctaw Nation of Oklahoma', label: 'The Choctaw Nation of Oklahoma' },
		{ value: 'The Muscogee (Creek) Nation', label: 'The Muscogee (Creek) Nation' },
		{ value: 'The Osage Nation', label: 'The Osage Nation' },
		{ value: 'The Seminole Nation of Oklahoma', label: 'The Seminole Nation of Oklahoma' },
		{ value: 'Thlopthlocco Tribal Town', label: 'Thlopthlocco Tribal Town' },
		{ value: 'Three Affiliated', label: 'Three Affiliated Tribes of the Fort Berthold Reservation, North Dakota' },
		{ value: 'Timbi-sha Shoshone', label: 'Timbisha Shoshone Tribe' },
		{ value: "Tohono O'odham", label: "Tohono O'odham Nation of Arizona" },
		{ value: "Tolowa Dee-ni'", label: "Tolowa Dee-ni' Nation" },
		{ value: 'Tonawanda', label: 'Tonawanda Band of Seneca' },
		{ value: 'Tonkawa', label: 'Tonkawa Tribe of Indians of Oklahoma' },
		{ value: 'Tonto Apache', label: 'Tonto Apache Tribe of Arizona' },
		{ value: 'Torres Martinez', label: 'Torres Martinez Desert Cahuilla Indians, California' },
		{ value: 'Togiak', label: 'Traditional Village of Togiak' },
		{ value: 'Tulalip', label: 'Tulalip Tribes of Washington' },
		{ value: 'Tule River', label: 'Tule River Indian Tribe of the Tule River Reservation, California' },
		{ value: 'Tuluksak', label: 'Tuluksak Native Community' },
		{ value: 'Tunica-Biloxi', label: 'Tunica-Biloxi Indian Tribe' },
		{ value: 'Tuolumne', label: 'Tuolumne Band of Me-Wuk Indians of the Tuolumne Rancheria of California' },
		{ value: 'Turtle Mountain', label: 'Turtle Mountain Band of Chippewa Indians of North Dakota' },
		{ value: 'Tuscarora', label: 'Tuscarora Nation' },
		{ value: 'Twenty-Nine Palms', label: 'Twenty-Nine Palms Band of Mission Indians of California' },
		{ value: 'Twin Hills', label: 'Twin Hills Village' },
		{ value: 'Ugashik', label: 'Ugashik Village' },
		{ value: 'Umkumiut', label: 'Umkumiut Native Village' },
		{ value: 'United Auburn', label: 'United Auburn Indian Community of the Auburn Rancheria of California' },
		{ value: 'United Keetoowah Band of Cherokee Indians in Oklahoma', label: 'United Keetoowah Band of Cherokee Indians in Oklahoma' },
		{ value: 'Upper Mattaponi Tribe', label: 'Upper Mattaponi Tribe' },
		{ value: 'Upper Sioux', label: 'Upper Sioux Community, Minnesota' },
		{ value: 'Upper Skagit', label: 'Upper Skagit Indian Tribe' },
		{ value: 'Ute', label: 'Ute Indian Tribe of the Uintah & Ouray Reservation, Utah' },
		{ value: 'Ute Mountain Ute', label: 'Ute Mountain Ute Tribe' },
		{ value: 'Benton', label: 'Utu Utu Gwaitu Paiute Tribe of the Benton Paiute Reservation, California' },
		{ value: 'Alakanuk', label: 'Village of Alakanuk' },
		{ value: 'Anaktuvuk Pass', label: 'Village of Anaktuvuk Pass' },
		{ value: 'Aniak', label: 'Village of Aniak' },
		{ value: 'Atmautluak', label: 'Village of Atmautluak' },
		{ value: "Bill Moore's Slough", label: "Village of Bill Moore's Slough" },
		{ value: 'Chefornak', label: 'Village of Chefornak' },
		{ value: "Clark's Point", label: 'Village of Clarks Point' },
		{ value: 'Crooked Creek', label: 'Village of Crooked Creek' },
		{ value: 'Dot Lake', label: 'Village of Dot Lake' },
		{ value: 'Iliamna', label: 'Village of Iliamna' },
		{ value: 'Kalskag', label: 'Village of Kalskag' },
		{ value: 'Kaltag', label: 'Village of Kaltag' },
		{ value: 'Kotlik', label: 'Village of Kotlik' },
		{ value: 'Lower Kalskag', label: 'Village of Lower Kalskag' },
		{ value: 'Ohogamiut', label: 'Village of Ohogamiut' },
		{ value: 'Red Devil', label: 'Village of Red Devil' },
		{ value: 'Sleetmute', label: 'Village of Sleetmute' },
		{ value: 'Solomon', label: 'Village of Solomon' },
		{ value: 'Stony River', label: 'Village of Stony River' },
		{ value: 'Venetie', label: 'Village of Venetie (See Native Village of Venetie Tribal Government)' },
		{ value: 'Wainwright', label: 'Village of Wainwright' },
		{ value: 'Walker River', label: 'Walker River Paiute Tribe of the Walker River Reservation, Nevada' },
		{ value: 'Wampanoag', label: 'Wampanoag Tribe of Gay Head (Aquinnah)' },
		{
			value: 'Washoe',
			label: 'Washoe Tribe of Nevada & California (Carson Colony, Dresslerville Colony, Woodfords Community, Stewart Community, & Washoe Ranches) ',
		},
		{ value: 'White Mountain', label: 'White Mountain Apache Tribe of the Fort Apache Reservation, Arizona' },
		{ value: 'Wichita', label: 'Wichita and Affiliated Tribes (Wichita, Keechi, Waco & Tawakonie), Oklahoma' },
		{ value: 'Wilton', label: 'Wilton Rancheria, California' },
		{ value: 'Winnebago', label: 'Winnebago Tribe of Nebraska' },
		{ value: 'Winnemucca', label: 'Winnemucca Indian Colony of Nevada' },
		{ value: 'Wiyot', label: 'Wiyot Tribe, California' },
		{ value: 'Wrangell', label: 'Wrangell Cooperative Association' },
		{ value: 'Wyandotte Nation', label: 'Wyandotte Nation' },
		{ value: 'Yakutat', label: 'Yakutat Tlingit Tribe' },
		{ value: 'Yankton', label: 'Yankton Sioux Tribe of South Dakota' },
		{ value: 'Yavapai-Apache', label: 'Yavapai-Apache Nation of the Camp Verde Indian Reservation, Arizona' },
		{ value: 'Yavapai-Prescott', label: 'Yavapai-Prescott Indian Tribe' },
		{ value: 'Yerington', label: 'Yerington Paiute Tribe of the Yerington Colony and Campbell Ranch, Nevada' },
		{ value: 'Yocha Dehe', label: 'Yocha Dehe Wintun Nation, California' },
		{ value: 'Yomba Shoshone', label: 'Yomba Shoshone Tribe of the Yomba Reservation, Nevada' },
		{ value: 'Ysleta Del Sur', label: 'Ysleta del Sur Pueblo' },
		{ value: 'Yuhaaviatam of San Manuel Nation', label: 'Yuhaaviatam of San Manuel Nation' },
		{ value: 'Andreafski', label: 'Yupiit of Andreafski' },
		{ value: 'Yurok', label: 'Yurok Tribe of the Yurok Reservation, California' },
		{ value: 'Pueblo of Zuni', label: 'Zuni Tribe of the Zuni Reservation, New Mexico' },
		{ value: 'Other', label: 'Other' },
	],
};

// same as google maps api
export enum PlaceType2 {
	/**
	 * indicates a first-order civil entity below the country level. Within the United States, these administrative levels are states.
	 * Not all nations exhibit these administrative levels. In most cases, `administrative_area_level_1` short names will closely match
	 * ISO 3166-2 subdivisions and other widely circulated lists; however this is not guaranteed as our geocoding results are based
	 * on a variety of signals and location data.
	 */
	administrative_area_level_1 = 'administrative_area_level_1',
	/**
	 * indicates a second-order civil entity below the country level. Within the United States, these administrative levels are counties.
	 * Not all nations exhibit these administrative levels.
	 */
	administrative_area_level_2 = 'administrative_area_level_2',
	/**
	 * indicates a third-order civil entity below the country level. This type indicates a minor civil division.
	 * Not all nations exhibit these administrative levels.
	 */
	administrative_area_level_3 = 'administrative_area_level_3',
	/**
	 * indicates a fourth-order civil entity below the country level. This type indicates a minor civil division.
	 * Not all nations exhibit these administrative levels.
	 */
	administrative_area_level_4 = 'administrative_area_level_4',
	/**
	 * indicates a fifth-order civil entity below the country level. This type indicates a minor civil division.
	 * Not all nations exhibit these administrative levels.
	 */
	administrative_area_level_5 = 'administrative_area_level_5',
	archipelago = 'archipelago',
	/** indicates a commonly-used alternative name for the entity. */
	colloquial_area = 'colloquial_area',
	continent = 'continent',
	/** indicates the national political entity, and is typically the highest order type returned by the Geocoder. */
	country = 'country',
	establishment = 'establishment',
	finance = 'finance',
	floor = 'floor',
	food = 'food',
	general_contractor = 'general_contractor',
	geocode = 'geocode',
	health = 'health',
	/** indicates a major intersection, usually of two major roads. */
	intersection = 'intersection',
	landmark = 'landmark',
	/** indicates an incorporated city or town political entity. */
	locality = 'locality',
	/** indicates a prominent natural feature. */
	natural_feature = 'natural_feature',
	/** indicates a named neighborhood */
	neighborhood = 'neighborhood',
	place_of_worship = 'place_of_worship',
	plus_code = 'plus_code',
	point_of_interest = 'point_of_interest',
	/** indicates a political entity. Usually, this type indicates a polygon of some civil administration. */
	political = 'political',
	post_box = 'post_box',
	/** indicates a postal code as used to address postal mail within the country. */
	postal_code = 'postal_code',
	postal_code_prefix = 'postal_code_prefix',
	postal_code_suffix = 'postal_code_suffix',
	postal_town = 'postal_town',
	/** indicates a named location, usually a building or collection of buildings with a common name */
	premise = 'premise',
	room = 'room',
	/** indicates a named route (such as "US 101"). */
	route = 'route',
	street_address = 'street_address',
	street_number = 'street_number',
	/**
	 * indicates a first-order civil entity below a locality. For some locations may receive one of the additional types:
	 * `sublocality_level_1` to `sublocality_level_5`. Each sublocality level is a civil entity. Larger numbers indicate a smaller
	 * geographic area.
	 */
	sublocality = 'sublocality',
	sublocality_level_1 = 'sublocality_level_1',
	sublocality_level_2 = 'sublocality_level_2',
	sublocality_level_3 = 'sublocality_level_3',
	sublocality_level_4 = 'sublocality_level_4',
	sublocality_level_5 = 'sublocality_level_5',
	/**
	 * indicates a first-order entity below a named location, usually a singular building within a collection of buildings with a
	 * common name.
	 */
	subpremise = 'subpremise',
	town_square = 'town_square',
}
