import { FieldTypes, Status } from 'shared/enums';
import { TemplateUserValue } from 'shared/types';
import { BlastProject } from 'app/common/types/BlastProject';

export const blastProject: Record<keyof BlastProject, any> = {
	entityID: 1,
	entity: 1,
	stateID: 1,
	order: {
		$arrayElemAt: [
			{
				$filter: {
					input: '$status',
					as: 'order',
					cond: { $eq: ['$$order.label', Status.Order] },
				},
			},
			-1,
		],
	},
	productTitle: 1,
	addOns: 1,
	website: 1,
	email: 1,
	phone: 1,
	price: 1,
	source: 1,
	shipping: 1,
	quantity: 1,
	transactionID: 1,
	paymentMethod: 1,
	paymentMerchant: 1,
	merchantReference: 1,
	ccProcessingFee: 1,
	einStatus: 1,
	boiStatus: 1,
	certificateStatus: 1,
	currentStatus: 1,
	emailFrequency: 1,
	group: 1,
	_id: 1,
};

const blastTemplateOptions = {
	company: {
		label: 'Company',
		param: '{{company}}',
		path: 'company',
		type: FieldTypes.Text,
	},
	customerName: {
		label: 'Customer Name',
		param: '{{customerName}}',
		path: 'customerName',
		type: FieldTypes.Text,
	},
	customerTitle: {
		label: 'Customer Title',
		param: '{{customerTitle}}',
		path: 'customerTitle',
		type: FieldTypes.Text,
	},
	transactionID: {
		label: 'Transaction ID',
		param: '{{transactionID}}',
		path: 'transactionID',
		type: FieldTypes.Text,
	},
	merchantReference: {
		label: 'MerchantReference',
		param: '{{merchantReference}}',
		path: 'merchantReference',
		type: FieldTypes.Text,
	},
	address: {
		label: 'Address',
		param: '{{address}}',
		path: 'address',
		type: FieldTypes.Text,
	},
	city: {
		label: 'City',
		param: '{{city}}',
		path: 'city',
		type: FieldTypes.Text,
	},
	state: {
		label: 'State',
		param: '{{state}}',
		path: 'state',
		type: FieldTypes.Text,
	},
	zip: {
		label: 'Zip',
		param: '{{zip}}',
		path: 'zip',
		type: FieldTypes.Text,
	},
	orderDate: {
		label: 'Order Date',
		param: '{{orderDate}}',
		path: 'orderDate',
		type: FieldTypes.Date,
	},
	total: {
		label: 'Order Total',
		param: '{{total}}',
		path: 'total',
		type: FieldTypes.Currency,
	},
	externalLink: {
		label: 'External Link',
		param: '{{externalLink}}',
		path: 'externalLink',
		type: FieldTypes.Link,
	},
	cardNo: {
		label: 'Card No',
		param: '{{cardNo}}',
		path: 'cardNo',
		type: FieldTypes.Text,
	},
	cardType: {
		label: 'Card Type',
		param: '{{cardType}}',
		path: 'cardType',
		type: FieldTypes.Text,
	},
	product: {
		label: 'Product',
		param: '{{product}}',
		path: 'product',
		type: FieldTypes.Text,
	},
	siteName: {
		label: 'Profile Name',
		param: '{{siteName}}',
		path: 'siteName',
		type: FieldTypes.Text,
	},
	siteWebAddress: {
		label: 'Profile Web Address',
		param: '{{siteWebAddress}}',
		path: 'siteWebAddress',
		type: FieldTypes.Text,
	},
	siteEmail: {
		label: 'Profile Email',
		param: '{{siteEmail}}',
		path: 'siteEmail',
		type: FieldTypes.Text,
	},
	sitePhone: {
		label: 'Profile Phone',
		param: '{{sitePhone}}',
		path: 'sitePhone',
		type: FieldTypes.Text,
	},
};

export const blastTemplateUserOptions: TemplateUserValue[] = Object.keys(blastTemplateOptions).map((key) => blastTemplateOptions[key]);
export type BlastUserOptionsValue = Record<keyof typeof blastTemplateOptions, any>;
