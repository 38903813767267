import { createTheme, defaultVariantColorsResolver, rem, VariantColorResolverResult } from '@mantine/core';
import { generateColors } from '@mantine/colors-generator';
import { useViewportSize } from '@mantine/hooks';

export const breakpoints: Record<string, number> = {
	xs: 767, //max
	sm: 768, //min
	md: 1024, //min
	lg: 1440, //min
	xl: 1920, //min
	xxl: 2560, //min
	huge: 3840, //min
};

export const useBreakPoint = () => {
	const { width, height } = useViewportSize();
	const gt = {
		xs: width >= breakpoints.sm,
		sm: width >= breakpoints.md,
		md: width >= breakpoints.lg,
		lg: width >= breakpoints.xl,
		xl: width >= breakpoints.xxl,
		xxl: width >= breakpoints.huge,
	};
	const lt = {
		sm: width < breakpoints.sm,
		md: width < breakpoints.md,
		lg: width < breakpoints.lg,
		xl: width < breakpoints.xl,
		xxl: width < breakpoints.xxl,
		huge: width < breakpoints.huge,
	};
	const xs = lt.sm;
	const sm = gt.xs && lt.md;
	const md = gt.sm && lt.lg;
	const lg = gt.md && lt.xl;
	const xl = gt.lg && lt.xxl;
	const xxl = gt.xl && lt.huge;
	const huge = gt.xxl;
	const name = (xs && 'xs') || (sm && 'sm') || (md && 'md') || (lg && 'lg') || (xl && 'xl') || (xxl && 'xxl') || 'huge';
	return {
		width,
		height,
		sizes: breakpoints,
		gt,
		lt,
		xs,
		sm,
		md,
		lg,
		xl,
		xxl,
		name,
		huge,
	};
};
type BreakPointsKey = keyof typeof breakpoints;
const getToPx = () => {
	return Object.assign({}, ...Object.keys(breakpoints).map((key: BreakPointsKey) => ({ [key]: breakpoints[key] + 'px' })));
};
export const theme = createTheme({
	cursorType: 'pointer',
	primaryColor: 'primary',
	colors: {
		primary: generateColors('#07090d'),
		// primary: ['#B8C6DD', '#3c5c94', '#365284', '#2f4773', '#283d63', '#213352', '#1B2942', '#18243b', '#152034', '#121c2e'],
		secondary: generateColors('#37474f'),
		accent: generateColors('#607d8b'),
		positive: generateColors('#388e3c'),
		negative: generateColors('#e53935'),
		info: generateColors('#17a2b8'),
		warning: generateColors('#ffc107'),
	},
	primaryShade: 9,
	// black: '#1b2942',
	breakpoints: getToPx(),
	fontFamily: 'Exo',
	fontSizes: {
		xs: rem(12),
		sm: rem(14),
		md: rem(16),
		lg: rem(18),
		xl: rem(20),
	},
	components: {
		DatePickerInput: {
			defaultProps: {
				popoverProps: { withArrow: true },
			},
		},
		Modal: {
			defaultProps: {
				variant: 'brand',
				closeOnClickOutside: false,
				closeOnEscape: false,
				closeButtonProps: {
					color: 'red',
				},
				centered: true,
				withCloseButton: true,
			},
		},
		Badge: {
			defaultProps: {
				variant: 'outline',
				radius: 'sm',
				px: 4,
			},
			classNames: {
				leftSection: 'tw-flex tw-mr-0',
				rightSection: 'tw-flex tw-ml-0',
			},
		},
		Menu: {
			defaultProps: {
				withArrow: true,
			},
			styles: {
				dropdown: {
					minWidth: '180px',
				},
			},
			classNames: {
				dropdown: 'tw-shadow-2xl tw-z-[99999]',
			},
		},
		FileInput: {
			defaultProps: {
				styles: { input: { minHeight: '36px' } },
				fileInputProps: { placeholder: 'Select file' },
			},
		},
		Text: {
			defaultProps: {
				component: 'div',
			},
		},
		ThemeIcon: {
			defaultProps: {
				variant: 'transparent',
			},
		},
		ActionIcon: {
			defaultProps: {
				variant: 'transparent',
			},
		},
		Popover: {
			defaultProps: {
				withArrow: true,
			},
			styles: {
				dropdown: {
					minWidth: '180px',
				},
			},
			classNames: {
				dropdown: 'tw-shadow-2xl tw-z-[99999]',
			},
		},
		Container: {
			defaultProps: {
				sizes: {
					xs: 540,
					sm: 768,
					md: 992,
					lg: 1200,
					xl: 1600,
					xxl: 2200,
					huge: 3000,
				},
			},
		},
		InputBase: {
			defaultProps: {
				variant: 'brand',
				inputWrapperOrder: ['label', 'input', 'description', 'error'],
			},
		},
		Image: {
			defaultProps: {
				fit: 'contain',
			},
		},
		Button: {
			defaultProps: {
				variant: 'brand',
			},
		},
		Dropzone: {
			defaultProps: {
				variant: 'brand',
			},
		},
		Input: {
			defaultProps: {
				variant: 'brand',
			},
		},
		InputWrapper: {
			defaultProps: {
				variant: 'brand',
			},
		},
		/*Input: {
      defaultProps: {

      },
      styles: () => ({
        icon: {
          pointerEvents: 'auto',
        },
      }),
      variants: {
        // @ts-ignore
        bordered: (theme, p, c) => ({
          input: {
            border: 'unset',
            // @ts-ignore
            borderRadius: rem(theme.radius[theme.defaultRadius]),
            // @ts-ignore
            minHeight: `calc(${theme.fontSizes[c.size]} + ${theme.fontSizes[c.size]})`,
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[1],
            paddingLeft: '8px',
            paddingRight: '8px',
          },
        }),
      },
    },*/
	},
	variantColorResolver: (input) => {
		const defaultResolvedColors = defaultVariantColorsResolver(input);
		if (input.variant === 'primary') {
			return {
				background: 'var(--mantine-color-primary-9)',
				hover: 'var(--mantine-color-primary-7)',
			} as VariantColorResolverResult;
		}
		if (input.variant === 'danger') {
			return {
				background: 'var(--mantine-color-red-9)',
				hover: 'var(--mantine-color-red-7)',
			} as VariantColorResolverResult;
		}
		if (input.variant === 'danger') {
			return {
				background: 'var(--mantine-color-negative-9)',
				hover: 'var(--mantine-color-negative-7)',
			} as VariantColorResolverResult;
		}
		if (input.variant === 'success') {
			return {
				background: 'var(--mantine-color-positive-9)',
				hover: 'var(--mantine-color-positive-7)',
			} as VariantColorResolverResult;
		}

		return defaultResolvedColors;
	},
});
