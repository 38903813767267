import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'yourKits',
	id: 'yourKits',
	loader: () => ({
		meta: {
			title: 'YourKits',
			to: { id: 'yourKitsHome' },
			htmlTitlePattern: '#title :: YourKits ',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/yourKits/Layout')).default }),
	children: [
		{
			id: 'yourKitsHome',
			index: true,
			path: '',
			lazy: async () => ({ Component: (await import('@/modules/yourKits/pages/Home')).default }),
			loader: () => ({
				meta: {
					title: 'Home',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'yourKitsIssues',
			index: true,
			path: 'issues',
			lazy: async () => ({ Component: (await import('@/modules/yourKits/pages/Issues')).default }),
			loader: () => ({
				meta: {
					title: 'Issues',
					disabled: true,
				},
			}),
		},
		{
			id: 'yourKitsReversals',
			index: true,
			path: 'reversals',
			lazy: async () => ({ Component: (await import('@/modules/yourKits/pages/Reversals')).default }),
			loader: () => ({
				meta: {
					title: 'Reversals',
					disabled: true,
				},
			}),
		},
		{
			id: 'yourKitsMarketers',
			index: true,
			path: 'marketers',
			lazy: async () => ({ Component: (await import('@/modules/yourKits/pages/settings/Marketers')).default }),
			loader: () => ({
				meta: {
					title: 'Marketers',
					disabled: true,
				},
			}),
		},
		{
			id: 'yourKitsProducts',
			index: true,
			path: 'products',
			lazy: async () => ({ Component: (await import('@/modules/yourKits/pages/settings/Products')).default }),
			loader: () => ({
				meta: {
					title: 'Products',
					disabled: true,
				},
			}),
		},
		{
			id: 'yourKitsProfilesSettings',
			index: true,
			path: 'settings/profiles',
			lazy: async () => ({ Component: (await import('@/modules/yourKits/pages/settings/Profiles')).default }),
			loader: () => ({
				meta: {
					title: 'Profile Settings',
					disabled: true,
				},
			}),
		},
		{
			id: 'yourKitsUtilitySettings',
			index: true,
			path: 'settings/utility',
			lazy: async () => ({ Component: (await import('@/modules/yourKits/pages/settings/Utility')).default }),
			loader: () => ({
				meta: {
					title: 'Utility Settings',
					disabled: true,
				},
			}),
		},
		{
			id: 'yourKitsPatients',
			index: true,
			path: 'patients/:profileID',
			lazy: async () => ({ Component: (await import('@/modules/yourKits/pages/Patients')).default }),
			loader: () => ({
				meta: {
					title: 'Patients',
					disabled: true,
				},
			}),
		},
		{
			id: 'yourKitsChatting',
			index: true,
			path: 'chatting/:type/:profileID',
			lazy: async () => ({ Component: (await import('@/modules/yourKits/pages/Chatting')).default }),
			loader: () => ({
				meta: {
					title: 'Chatting',
					disabled: true,
				},
			}),
		},
		{
			id: 'yourKitsPatientDetails',
			index: true,
			path: 'patients/details/:patientID',
			lazy: async () => ({ Component: (await import('@/modules/yourKits/pages/Patient')).default }),
			loader: () => ({
				meta: {
					title: 'Patients Details',
					disabled: true,
				},
			}),
		},
		{
			id: 'yourKitsCostReport',
			index: true,
			path: 'report/cost/:profileID',
			lazy: async () => ({ Component: (await import('@/modules/yourKits/pages/reports/Cost')).default }),
			loader: () => ({
				meta: {
					title: 'Cost Reporting',
					disabled: true,
				},
			}),
		},
		{
			id: 'yourKitsSmsReport',
			index: true,
			path: 'report/Sms/:profileID',
			lazy: async () => ({ Component: (await import('@/modules/yourKits/pages/reports/Sms')).default }),
			loader: () => ({
				meta: {
					title: 'Sms Reporting',
					disabled: true,
				},
			}),
		},
		{
			id: 'yourKitsVoiceReport',
			index: true,
			path: 'report/Voice/:profileID',
			lazy: async () => ({ Component: (await import('@/modules/yourKits/pages/reports/Voice')).default }),
			loader: () => ({
				meta: {
					title: 'Voice Reporting',
					disabled: true,
				},
			}),
		},
	],
};

export default route;
