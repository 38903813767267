import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'reports',
	loader: () => ({
		meta: {
			title: 'Reports',
			disabled: true,
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/fictitious/reports/Layout')).default }),
	children: [
		{
			path: 'pnl',
			index: true,
			id: 'fictitiousPnlReport',
			loader: () => ({
				meta: {
					title: 'Profit and Loss',
					htmlTitle: 'Fictitious Profit and Loss Report',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/fictitious/reports/pages/PnL')).default }),
		},
		{
			path: 'order',
			index: true,
			id: 'fictitiousOrderReport',
			loader: () => ({
				meta: {
					title: 'Order',
					htmlTitle: 'Fictitious Order Report',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/fictitious/reports/pages/Order')).default }),
		},
		{
			path: 'order/date',
			index: true,
			id: 'fictitiousOrderByDateReport',
			loader: () => ({
				meta: {
					title: 'Order By Date',
					htmlTitle: 'Fictitious Order By Date Report',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/fictitious/reports/pages/OrderByDate')).default }),
		},
	],
};
export default route;
