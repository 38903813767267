import { useRouteError } from 'react-router-dom';
import { IconAlertTriangle } from '@tabler/icons-react';
import { TwContainer } from '@/lib/components/TwContainer';

const NotFound = () => {
	const error = useRouteError() as Error;
	return (
		<TwContainer className={'tw-py-96  tw-mx-auto tw-text-center tw-justify-center tw-items-center'}>
			<IconAlertTriangle
				color={'red'}
				size={96}
			/>
			<h1 className={'tw-pt-6 tw-text-4xl tw-font-semibold domaine'}>An error Occurred!</h1>
			<h3 className={'tw-text-red-500'}>{error.message}</h3>
			<div className={'tw-text-left tw-text-red-400'}>{error.stack}</div>
		</TwContainer>
	);
};

export default NotFound;
