import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes.tsx';

const routes: Array<RouteObject> = [
	{
		id: 'workMail',
		index: true,
		path: 'aws/workMail',
		lazy: async () => ({ Component: (await import('@/modules/settings/aws/pages/Workmail')).default }),
		loader: () => ({
			meta: {
				title: 'AWS Workmail',
				disabled: true,
			} as RouteMeta,
		}),
	},
	{
		id: 'hostedZone',
		index: true,
		path: 'aws/hostedZone',
		lazy: async () => ({ Component: (await import('@/modules/settings/aws/pages/HostedZones')).default }),
		loader: () => ({
			meta: {
				title: 'AWS HostedZones',
				disabled: true,
			} as RouteMeta,
		}),
	},
];

export default routes;
