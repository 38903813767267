import { Button, ButtonProps, Group, MantineColor, Popover, PopoverProps, Stack, Text, TextProps, ThemeIcon } from '@mantine/core';
import { ReactNode, useEffect } from 'react';
import { IconTrash } from '@tabler/icons-react';
import { useDisclosure, useSetState } from '@mantine/hooks';

const PopupConfirmation = ({
	title,
	message,
	icon,
	onConfirmed,
	action,
	color,
	children,
	caption,
	renderFormItem,
	formData,
	captionProps,
	closeBtnProps,
	submitBtnProps,
	confirmationText = 'Confirmation',
	...rest
}: {
	onConfirmed: (data?: Record<string, any>) => void;
	action?: string;
	color?: MantineColor;
	title?: ReactNode;
	message?: ReactNode;
	caption?: ReactNode;
	captionProps?: Omit<TextProps, 'children' | 'component'>;
	icon?: ReactNode;
	children: ReactNode;
	renderFormItem?: (props: { data: Record<string, any>; setData: (data: Record<string, any>) => void }) => ReactNode;
	formData?: Record<string, any>;
	closeBtnProps?: ButtonProps;
	submitBtnProps?: ButtonProps;
	confirmationText?: string;
} & Omit<PopoverProps, 'children'>) => {
	const [data, setData] = useSetState<Record<string, any>>({});
	const [opened, { toggle, close }] = useDisclosure(false);
	if (!action) {
		action = 'delete';
	}
	useEffect(() => {
		if (formData) setData(formData);
		else setData({});
	}, [formData]);
	return (
		<Popover
			withinPortal={false}
			opened={opened}
			position={'left-start'}
			withArrow
			{...rest}>
			<Popover.Target>
				<span
					onClick={toggle}
					className={'tw-flex'}>
					{children}
				</span>
			</Popover.Target>
			<Popover.Dropdown className={'tw-p-4'}>
				<Group>
					<ThemeIcon>{icon || <IconTrash color={color || 'red'} />}</ThemeIcon>
					<Stack gap={0}>
						<Text
							component={'div'}
							c={color || 'red'}
							className={'tw-font-semibold tw-capitalize'}>
							{title || `${action} ${confirmationText}`}
						</Text>
						<Text
							component={'div'}
							c={'dimmed'}>
							{message || `Would you really like to ${action} this?`}
						</Text>
						{!!caption && (
							<Text
								component={'div'}
								c={'gray.5'}
								size={'xs'}
								{...captionProps}>
								{caption}
							</Text>
						)}
						{renderFormItem &&
							renderFormItem({
								setData,
								data,
							})}
					</Stack>
				</Group>
				<Group className={'tw-justify-end tw-mt-4'}>
					<Button
						variant={'filled'}
						onClick={() => {
							if (onConfirmed) {
								onConfirmed(data);
							}
							close();
						}}
						color={color || 'red'}
						size={'xs'}
						children={'Yes'}
						{...submitBtnProps}
					/>
					<Button
						color={'secondary'}
						onClick={close}
						variant={'outline'}
						size={'xs'}
						children={'No'}
						{...closeBtnProps}
					/>
				</Group>
			</Popover.Dropdown>
		</Popover>
	);
};
export default PopupConfirmation;
