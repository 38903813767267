import {
	Badge,
	BadgeProps,
	CheckIcon,
	CloseButton,
	Combobox,
	Group,
	Input,
	InputBase,
	InputBaseProps,
	Loader,
	Pill,
	PillsInput,
	PillsInputProps,
	ScrollArea,
	Stack,
	Text,
	Title,
	useCombobox,
} from '@mantine/core';
import { forwardRef, ReactElement, Ref, useMemo, useState } from 'react';
import { uniq } from 'lodash';

export type ComboboxData<T> = {
	value: T;
	label: string;
	description?: string;
	disabled?: boolean;
	_id: string;
	badge?: string;
};

type ComboboxSingleProps<T> = {
	onChange: (value: T) => void;
	value?: T;
};
type ComboboxMultipleProps<T> = {
	onChange: (value: Array<T>) => void;
	value?: Array<T>;
};
type ComboboxProps<T, B extends boolean> = {
	badgeProps?: BadgeProps;
	readonly?: boolean;
	data: Array<ComboboxData<T>>;
	searchable?: boolean;
	onSearchChange?: (value: string) => void;
	searchValue?: string;
	placeholder?: string;
	searchPlaceholder?: string;
	loading?: boolean;
	clearable?: boolean;
	multiple?: true;
} & (B extends true ? ComboboxMultipleProps<T> & Omit<PillsInputProps, 'onChange' | 'placeholder'> : ComboboxSingleProps<T> & InputBaseProps);

const AppCombo = forwardRef(
	<T = any, B extends boolean = false>(
		{
			readonly,
			data,
			onChange,
			clearable,
			value,
			searchValue,
			searchable,
			onSearchChange,
			searchPlaceholder,
			loading,
			placeholder,
			multiple,
			badgeProps,
			...rest
		}: ComboboxProps<T, B>,
		ref: Ref<any>,
	) => {
		const [search, setSearch] = useState(searchValue || '');
		const combobox = useCombobox({
			onDropdownClose: () => {
				combobox.resetSelectedOption();
				combobox.focusTarget();
			},

			onDropdownOpen: () => {
				if (searchable) combobox.focusSearchInput();
			},
		});
		const options = useMemo(
			() =>
				data
					.filter(
						(d) => d.label?.toLowerCase().includes(search.toLowerCase().trim()) || d.description?.toLowerCase().includes(search.toLowerCase().trim()),
					)
					.map(({ value: v, label, description, _id, disabled, badge }, index) => (
						<Combobox.Option
							disabled={disabled}
							key={_id || index}
							value={JSON.stringify({ value: v })}
							active={multiple && value && value instanceof Array ? value.includes(v) : v == value}>
							<Stack gap={0}>
								<Group gap={'xs'}>
									{(multiple && value && value instanceof Array ? value.includes(v) : v == value) && <CheckIcon size={12} />}
									<Title order={6}>{label}</Title>
									{badge !== undefined && <Badge {...badgeProps}>{badge}</Badge>}
								</Group>
								{description && <Text c={'dimmed'}>{description}</Text>}
							</Stack>
						</Combobox.Option>
					)),
			[data, search],
		);
		const selectedOption = useMemo(() => {
			if (value instanceof Array) {
				// @ts-ignore
				return data.find((item) => (typeof item.value === 'object' && item.value ? value.includes(item.value._id) : value.includes(item.value)));
				// @ts-ignore
			} else return data.find((item) => (typeof item.value === 'object' && item.value ? item.value._id === value?._id : item.value === value));
		}, [data, value]);
		const values = useMemo(() => {
			if (value && value instanceof Array)
				return value.map((item: any) => (
					<Pill
						key={item}
						withRemoveButton
						// @ts-ignore
						onRemove={() => onChange(uniq(value.filter((v) => v !== item)))}>
						{data.find((o) => o.value === item)?.label}
					</Pill>
				));
			else return [];
		}, [value]);

		return (
			<Combobox
				readOnly={readonly}
				withinPortal
				store={combobox}
				onOptionSubmit={(val) => {
					if (multiple && value instanceof Array) {
						const v = JSON.parse(val).value;
						console.log(v);
						// @ts-ignore
						onChange(uniq([...value, v]));
					} else {
						onChange(JSON.parse(val).value);
					}
					combobox.closeDropdown();
					setSearch('');
					onSearchChange?.('');
				}}>
				<Combobox.Target>
					{multiple && value instanceof Array ? (
						<PillsInput
							ref={ref}
							{...rest}
							pointer
							onClick={() => combobox.toggleDropdown()}>
							<Pill.Group>
								{values.length > 0 ? values : <Input.Placeholder>Pick one or more values</Input.Placeholder>}

								<Combobox.EventsTarget>
									<PillsInput.Field
										type="hidden"
										onKeyDown={(event) => {
											if (event.key === 'Backspace') {
												event.preventDefault();
											}
										}}
									/>
								</Combobox.EventsTarget>
							</Pill.Group>
						</PillsInput>
					) : (
						// @ts-ignore
						<InputBase
							ref={ref}
							{...rest}
							styles={{ input: { height: 'unset' } }}
							rightSection={
								clearable && value !== null ? (
									<CloseButton
										size="sm"
										onMouseDown={(event) => event.preventDefault()}
										// @ts-ignore
										onClick={() => onChange(null)}
										aria-label="Clear value"
									/>
								) : (
									<Combobox.Chevron />
								)
							}
							component="button"
							type="button"
							pointer
							rightSectionPointerEvents={value === null ? 'none' : 'all'}
							onClick={() => combobox.toggleDropdown()}>
							{selectedOption?.label ? (
								<Group className={'tw-gap-x-4 tw-gap-y-0'}>
									<Title order={6}>{selectedOption.label}</Title>
									{selectedOption.description && (
										<Text
											size={'sm'}
											c={'dimmed'}>
											{selectedOption.description}
										</Text>
									)}
								</Group>
							) : (
								<Input.Placeholder>{placeholder || 'Select an option'}</Input.Placeholder>
							)}
						</InputBase>
					)}
				</Combobox.Target>
				<Combobox.Dropdown>
					{searchable && (
						<Combobox.Search
							value={search}
							onKeyUp={(event) => {
								if (event.key === 'Enter') {
									onSearchChange?.(event.currentTarget.value);
									setSearch(event.currentTarget.value);
								}
							}}
							onChange={(event) => {
								combobox.openDropdown();
								combobox.updateSelectedOptionIndex();
								onSearchChange?.(event.currentTarget.value);
								setSearch(event.currentTarget.value);
							}}
							rightSectionProps={{ style: { zIndex: 1001 } }}
							rightSection={loading && <Loader size={'sm'} />}
							placeholder={searchPlaceholder || 'Type search value here...'}
						/>
					)}
					<ScrollArea.Autosize mah={300}>
						<Combobox.Options>{options.length > 0 ? options : <Combobox.Empty>Nothing found</Combobox.Empty>}</Combobox.Options>
					</ScrollArea.Autosize>
				</Combobox.Dropdown>
			</Combobox>
		);
	},
);

export default AppCombo as <T = any, B extends boolean = false>(
	props: ComboboxProps<T, B> & {
		ref?: Ref<any>;
	},
) => ReactElement;
