import { TemplateUserValue } from 'shared/types';
import { FieldTypes, PaymentMerchant, PaymentMethod, Status } from 'shared/enums';
import { OrderStatusEntity } from 'app/common/order-status.entity';
import { InvoiceItemEntity } from 'modules/invoice/entities/invoice.item.entity';

export type InvoiceTableProject = {
	payment: OrderStatusEntity;
	completed: OrderStatusEntity;
	pending: OrderStatusEntity;
	refund: OrderStatusEntity;
	profile: string;
	email: string;
	phone: string;
	total: number;
	cost: number;
	address: string;
	city: string;
	state: string;
	zip: string;
	customer: string;
	company: string;
	transactionID: string;
	paymentMethod: PaymentMethod;
	paymentMerchant: PaymentMerchant;
	merchantReference: string;
	currentStatus: Status;
	items: InvoiceItemEntity[];
	_id: string;
};

export const invoiceProject: Record<keyof InvoiceTableProject, any> = {
	pending: {
		$arrayElemAt: [
			{
				$filter: {
					input: '$status',
					as: 'pending',
					cond: { $eq: ['$$pending.label', Status.Pending] },
				},
			},
			-1,
		],
	},
	payment: {
		$arrayElemAt: [
			{
				$filter: {
					input: '$status',
					as: 'payment',
					cond: { $eq: ['$$payment.label', Status.Payment] },
				},
			},
			-1,
		],
	},
	completed: {
		$arrayElemAt: [
			{
				$filter: {
					input: '$status',
					as: 'completed',
					cond: { $eq: ['$$completed.label', Status.Completed] },
				},
			},
			-1,
		],
	},
	refund: {
		$arrayElemAt: [
			{
				$filter: {
					input: '$status',
					as: 'refund',
					cond: { $eq: ['$$refund.label', Status.Refund] },
				},
			},
			-1,
		],
	},
	items: 1,
	profile: 1,
	email: 1,
	phone: 1,
	transactionID: 1,
	paymentMethod: 1,
	merchantReference: 1,
	currentStatus: 1,
	cost: 1,
	total: 1,
	paymentMerchant: 1,
	address: 1,
	city: 1,
	state: 1,
	zip: 1,
	customer: 1,
	company: 1,
	_id: 1,
};

const invoiceTemplateOptions = {
	company: {
		label: 'Company',
		param: '{{company}}',
		path: 'company',
		type: FieldTypes.Text,
	},
	customerName: {
		label: 'Customer Name',
		param: '{{customerName}}',
		path: 'customerName',
		type: FieldTypes.Text,
	},
	transactionID: {
		label: 'Transaction ID',
		param: '{{transactionID}}',
		path: 'transactionID',
		type: FieldTypes.Text,
	},
	merchantReference: {
		label: 'MerchantReference',
		param: '{{merchantReference}}',
		path: 'merchantReference',
		type: FieldTypes.Text,
	},
	address: {
		label: 'Address',
		param: '{{address}}',
		path: 'address',
		type: FieldTypes.Text,
	},
	city: {
		label: 'City',
		param: '{{city}}',
		path: 'city',
		type: FieldTypes.Text,
	},
	state: {
		label: 'State',
		param: '{{state}}',
		path: 'state',
		type: FieldTypes.Text,
	},
	zip: {
		label: 'Zip',
		param: '{{zip}}',
		path: 'zip',
		type: FieldTypes.Text,
	},
	orderDate: {
		label: 'Order Date',
		param: '{{orderDate}}',
		path: 'orderDate',
		type: FieldTypes.Date,
	},
	paymentDate: {
		label: 'Payment Date',
		param: '{{paymentDate}}',
		path: 'paymentDate',
		type: FieldTypes.Date,
	},
	completedDate: {
		label: 'Completed Date',
		param: '{{completedDate}}',
		path: 'completedDate',
		type: FieldTypes.Date,
	},
	refundDate: {
		label: 'Refund Date',
		param: '{{refundDate}}',
		path: 'refundDate',
		type: FieldTypes.Date,
	},
	total: {
		label: 'Order Total',
		param: '{{total}}',
		path: 'total',
		type: FieldTypes.Currency,
	},
	invoiceLink: {
		label: 'Declined / Invoice Link',
		param: '{{invoiceLink}}',
		path: 'invoiceLink',
		type: FieldTypes.Text,
	},
	cardNo: {
		label: 'Card No',
		param: '{{cardNo}}',
		path: 'cardNo',
		type: FieldTypes.Text,
	},
	cardType: {
		label: 'Card Type',
		param: '{{cardType}}',
		path: 'cardType',
		type: FieldTypes.Text,
	},
	bankMessage: {
		label: 'Bank Message',
		param: '{{bankMessage}}',
		path: 'bankMessage',
		type: FieldTypes.Text,
	},
	siteName: {
		label: 'Profile Name',
		param: '{{siteName}}',
		path: 'siteName',
		type: FieldTypes.Text,
	},
	siteWebAddress: {
		label: 'Profile Web Address',
		param: '{{siteWebAddress}}',
		path: 'siteWebAddress',
		type: FieldTypes.Text,
	},
	siteEmail: {
		label: 'Profile Email',
		param: '{{siteEmail}}',
		path: 'siteEmail',
		type: FieldTypes.Text,
	},
	sitePhone: {
		label: 'Profile Phone',
		param: '{{sitePhone}}',
		path: 'sitePhone',
		type: FieldTypes.Text,
	},
};

export const invoiceTemplateUserOptions: TemplateUserValue[] = Object.keys(invoiceTemplateOptions).map((key) => invoiceTemplateOptions[key]);
export type InvoiceTemplateUserOptionsValue = Record<keyof typeof invoiceTemplateOptions, any>;
