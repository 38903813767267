import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'users',
	id: 'userGroupLayout',
	lazy: async () => ({ Component: (await import('@/modules/user/Layout')).default }),
	children: [
		{
			id: 'userGroups',
			index: true,
			path: 'groups',
			lazy: async () => ({ Component: (await import('@/modules/user/pages/Groups')).default }),
			loader: () => ({
				meta: {
					title: 'Groups',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'users',
			index: true,
			path: '',
			lazy: async () => ({ Component: (await import('@/modules/user/pages/Users')).default }),
			loader: () => ({
				meta: {
					title: 'Users',
					disabled: true,
				} as RouteMeta,
			}),
		},
	],
};

export default route;
