import { Node, NodeViewRendererProps } from '@tiptap/core';
import { mergeAttributes, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';

export const ExtensionButtonComponent = Node.create({
	name: 'buttonComponent',
	content: 'text*',
	group: 'inline',
	inline: true,
	atom: true,
	addAttributes() {
		return {
			type: {
				default: 'buttonComponent',
			},
			href: {
				default: '',
			},
			label: {
				default: '',
			},
			color: {
				default: '#1b2942',
			},
		};
	},
	// @ts-ignore
	parseHTML() {
		return [
			{
				tag: 'div',
				getAttrs: (element) => {
					if (typeof element === 'string') return false;
					return element?.hasAttribute('type') && element?.getAttribute('type') === 'buttonComponent';
				},
			},
		];
	},

	renderHTML({ HTMLAttributes, node }) {
		return [
			'div',
			mergeAttributes(HTMLAttributes, {
				style: 'display:flex;align-items:center;justify-content:center;',
				type: 'buttonComponent',
			}),
			[
				'div',
				{
					title: HTMLAttributes.label,
					style: `background-color: ${HTMLAttributes.color || '#1b2942'}; border-radius: 6px;  cursor: pointer;width: fit-content;margin: 0 auto; display:flex;`,
				},
				[
					'a',
					{
						style: 'margin:8px 24px; color: white; text-decoration: unset; font-size:18px;',
						href: HTMLAttributes.href,
						target: '_blank',
						rel: 'noopener noreferrer nofollow',
					},
					node.content.firstChild?.text,
				],
			],
		];
	},
	addNodeView() {
		return ReactNodeViewRenderer((props: NodeViewRendererProps) => {
			return (
				<NodeViewWrapper
					label={props.node.attrs.label}
					color={props.node.attrs.color}
					type={'buttonComponent'}
					as={'div'}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<div
						title={props.node.attrs.title}
						style={{
							backgroundColor: props.node.attrs.color || '#1b2942',
							borderRadius: '6px',
							cursor: 'text',
							width: 'fit-content',
							margin: '0 auto',
							display: 'flex',
						}}>
						<a
							rel={'noopener noreferrer nofollow'}
							href={props.node.attrs.href}
							target={'_blank'}
							style={{ margin: '8px 24px', color: 'white', textDecoration: 'unset', fontSize: '18px' }}>
							{props.node.content.firstChild?.text}
						</a>
					</div>
				</NodeViewWrapper>
			);
		});
	},
});
