import { TemplateUserValue } from 'shared/types';
import { FieldTypes, SiteGroup, Status } from 'shared/enums';
import { LaborLawOrderProject } from 'app/common/types/LaborLawOrderProject';

export const laborLawProject: Record<keyof LaborLawOrderProject, any> = {
	group: 1,
	entityID: 1,
	entity: 1,
	mailingDate: 1,
	order: {
		$arrayElemAt: [
			{
				$filter: {
					input: '$status',
					as: 'completed',
					cond: { $eq: ['$$completed.label', Status.Order] },
				},
			},
			0,
		],
	},
	completed: {
		$arrayElemAt: [
			{
				$filter: {
					input: '$status',
					as: 'status',
					cond: { $and: [{ $eq: ['$$status.label', Status.Completed] }, { $eq: ['$$status.group', SiteGroup.LaborLaw] }] },
				},
			},
			-1,
		],
	},
	issue: {
		$arrayElemAt: [
			{
				$filter: {
					input: '$status',
					as: 'status',
					cond: { $and: [{ $eq: ['$$status.label', Status.Issue] }, { $eq: ['$$status.group', SiteGroup.LaborLaw] }] },
				},
			},
			-1,
		],
	},
	partialRefunds: {
		$filter: {
			input: '$status',
			as: 'partialRefund',
			cond: { $eq: ['$$partialRefund.label', Status.PartialRefund] },
		},
	},
	refund: {
		$arrayElemAt: [
			{
				$filter: {
					input: '$status',
					as: 'refund',
					cond: { $eq: ['$$refund.label', Status.Refund] },
				},
			},
			-1,
		],
	},
	returned: {
		$arrayElemAt: [
			{
				$filter: {
					input: '$status',
					as: 'status',
					cond: { $and: [{ $eq: ['$$status.label', Status.Returned] }, { $eq: ['$$status.group', SiteGroup.LaborLaw] }] },
				},
			},
			-1,
		],
	},
	resend: {
		$arrayElemAt: [
			{
				$filter: {
					input: '$status',
					as: 'status',
					cond: { $and: [{ $eq: ['$$status.label', Status.Resend] }, { $eq: ['$$status.group', SiteGroup.LaborLaw] }] },
				},
			},
			-1,
		],
	},
	refundRequested: {
		$arrayElemAt: [
			{
				$filter: {
					input: '$status',
					as: 'resend',
					cond: { $eq: ['$$resend.label', Status.RefundRequested] },
				},
			},
			-1,
		],
	},
	refundStatus: 1,
	website: 1,
	product: 1,
	productTitle: 1,
	email: 1,
	phone: 1,
	price: 1,
	source: 1,
	shipping: 1,
	addOns: 1,
	quantity: 1,
	transactionID: 1,
	paymentMethod: 1,
	paymentMerchant: 1,
	merchantReference: 1,
	ccProcessingFee: 1,
	refundRequestStatus: 1,
	currentStatus: 1,
	secondaryStatus: 1,
	_id: 1,
	stateID: 1,
};

const laborLawTemplateOptions = {
	businessID: {
		label: 'BusinessID',
		param: '{{businessID}}',
		path: 'businessID',
		type: FieldTypes.Text,
	},
	company: {
		label: 'Company',
		param: '{{company}}',
		path: 'company',
		type: FieldTypes.Text,
	},
	customerName: {
		label: 'Customer Name',
		param: '{{customerName}}',
		path: 'customerName',
		type: FieldTypes.Text,
	},
	transactionID: {
		label: 'Transaction ID',
		param: '{{transactionID}}',
		path: 'transactionID',
		type: FieldTypes.Text,
	},
	merchantReference: {
		label: 'MerchantReference',
		param: '{{merchantReference}}',
		path: 'merchantReference',
		type: FieldTypes.Text,
	},
	address: {
		label: 'Address',
		param: '{{address}}',
		path: 'address',
		type: FieldTypes.Text,
	},
	city: {
		label: 'City',
		param: '{{city}}',
		path: 'city',
		type: FieldTypes.Text,
	},
	state: {
		label: 'State',
		param: '{{state}}',
		path: 'state',
		type: FieldTypes.Text,
	},
	zip: {
		label: 'Zip',
		param: '{{zip}}',
		path: 'zip',
		type: FieldTypes.Text,
	},
	orderDate: {
		label: 'Order Date',
		param: '{{orderDate}}',
		path: 'orderDate',
		type: FieldTypes.Date,
	},
	infoSubmittedDate: {
		label: 'Info Submitted Date',
		param: '{{infoSubmittedDate}}',
		path: 'infoSubmittedDate',
		type: FieldTypes.Date,
	},
	completedDate: {
		label: 'Completed Date',
		param: '{{completedDate}}',
		path: 'completedDate',
		type: FieldTypes.Date,
	},
	refundDate: {
		label: 'Refund Date',
		param: '{{refundDate}}',
		path: 'refundDate',
		type: FieldTypes.Date,
	},
	total: {
		label: 'Order Total',
		param: '{{total}}',
		path: 'total',
		type: FieldTypes.Currency,
	},
	invoiceLink: {
		label: 'Declined / Invoice Link',
		param: '{{invoiceLink}}',
		path: 'invoiceLink',
		type: FieldTypes.Link,
	},
	refundLink: {
		label: 'Refund Link',
		param: '{{refundLink}}',
		path: 'refundLink',
		type: FieldTypes.Link,
	},
	einFilingLink: {
		label: 'EIN Filing Link',
		param: '{{einFilingLink}}',
		path: 'einFilingLink',
		type: FieldTypes.Link,
	},
	boirFillingLing: {
		label: 'BOIR Filing Link',
		param: '{{boirFillingLing}}',
		path: 'boirFillingLing',
		type: FieldTypes.Link,
	},
	externalLink: {
		label: 'External Link',
		param: '{{externalLink}}',
		path: 'externalLink',
		type: FieldTypes.Link,
	},
	cardNo: {
		label: 'Card No',
		param: '{{cardNo}}',
		path: 'cardNo',
		type: FieldTypes.Text,
	},
	cardType: {
		label: 'Card Type',
		param: '{{cardType}}',
		path: 'cardType',
		type: FieldTypes.Text,
	},
	product: {
		label: 'Product',
		param: '{{product}}',
		path: 'product',
		type: FieldTypes.Text,
	},
	bankMessage: {
		label: 'Bank Message',
		param: '{{bankMessage}}',
		path: 'bankMessage',
		type: FieldTypes.Text,
	},
	siteName: {
		label: 'Profile Name',
		param: '{{siteName}}',
		path: 'siteName',
		type: FieldTypes.Text,
	},
	siteWebAddress: {
		label: 'Profile Web Address',
		param: '{{siteWebAddress}}',
		path: 'siteWebAddress',
		type: FieldTypes.Text,
	},
	siteEmail: {
		label: 'Profile Email',
		param: '{{siteEmail}}',
		path: 'siteEmail',
		type: FieldTypes.Text,
	},
	sitePhone: {
		label: 'Profile Phone',
		param: '{{sitePhone}}',
		path: 'sitePhone',
		type: FieldTypes.Text,
	},
};

export const laborLawTemplateUserOptions: TemplateUserValue[] = Object.keys(laborLawTemplateOptions).map((key) => laborLawTemplateOptions[key]);
export type LaborLawTemplateUserOptionsValue = Record<keyof typeof laborLawTemplateOptions, any>;
