import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'tasks',
	lazy: async () => ({ Component: (await import('@/modules/task/Layout')).default }),
	children: [
		{
			path: '',
			index: true,
			id: 'tasks',
			loader: () => ({
				meta: {
					title: 'Task Manager',
					disabled: true,
					htmlTitlePattern: 'Task Manager',
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/task/pages/Tasks')).default }),
		},
	],
};
export default route;
