import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'records',
	loader: () => ({
		meta: {
			title: 'Records',
			to: { id: 'recordsHome' },
			htmlTitlePattern: '#title :: Entities',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/records/Layout')).default }),
	children: [
		{
			path: '',
			index: true,
			id: 'recordsHome',
			loader: () => ({
				meta: {
					title: 'Home',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/records/pages/Home')).default }),
		},
		{
			path: ':abbreviation',
			index: true,
			id: 'recordsList',
			loader: (args) => ({
				meta: {
					title: args.params.abbreviation,
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/records/pages/List')).default }),
		},
	],
};
export default route;
