import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'reports',
	loader: () => ({
		meta: {
			title: 'Reports',
			htmlTitlePattern: '#title :: Reports ',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/reports/Layout')).default }),
	children: [
		{
			path: 'conversion',
			index: true,
			id: 'conversionReport',
			loader: () => ({
				meta: {
					title: 'Conversion',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/reports/pages/Conversion')).default }),
		},
		{
			path: 'jobHistory',
			index: true,
			id: 'jobHistoryReport',
			loader: () => ({
				meta: {
					title: 'Conversion',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/reports/pages/JobHistory')).default }),
		},
	],
};

export default route;
