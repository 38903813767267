import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';
import EmailTemplates from '@/modules/settings/pages/EmailTemplates.tsx';
import { SiteGroup } from 'shared/enums.ts';

const route: RouteObject = {
	path: 'settings',
	loader: () => ({
		meta: {
			title: 'Settings',
			disabled: true,
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/fictitious/settings/Layout')).default }),
	children: [
		{
			path: 'website',
			index: true,
			id: 'fictitiousWebsite',
			loader: () => ({
				meta: {
					title: 'Website',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/fictitious/settings/pages/Website')).default }),
		},
		{
			path: 'crm',
			index: true,
			id: 'fictitiousCrm',
			loader: () => ({
				meta: {
					title: 'CRM',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/fictitious/settings/pages/Crm')).default }),
		},
		{
			path: 'products',
			index: true,
			id: 'fictitiousProducts',
			loader: () => ({
				meta: {
					title: 'Products',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/fictitious/settings/pages/Products')).default }),
		},
		{
			id: 'fictitiousEmailTemplates',
			index: true,
			path: 'templates/email',
			element: <EmailTemplates group={SiteGroup.Fictitious} />,
			loader: () => ({
				meta: {
					title: 'Email Templates',
					disabled: true,
				} as RouteMeta,
			}),
		},
	],
};
export default route;
