import ReactDOM from 'react-dom/client';
import '@/css/app.scss';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/nprogress/styles.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routes } from '@/pages/routes';
import { MantineProvider } from '@mantine/core';
import { theme } from '@/layout/theme.ts';
import { NavigationProgress } from '@mantine/nprogress';

// @ts-ignore
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<MantineProvider theme={theme}>
		<NavigationProgress
			color={'red'}
			zIndex={999999}
		/>
		<RouterProvider router={createBrowserRouter(routes)} />
	</MantineProvider>,
);
