import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';
import awsRoutes from './aws';

const route: RouteObject = {
	path: 'settings',
	loader: () => ({
		meta: {
			title: 'Settings',
			htmlTitlePattern: '#title :: Settings ',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/settings/Layout')).default }),
	children: [
		{
			id: 'websiteBlogs',
			index: true,
			path: 'websites/blogs',
			lazy: async () => ({ Component: (await import('@/modules/settings/pages/Blogs')).default }),
			loader: () => ({
				meta: {
					title: 'Blogs',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'paymentProfiles',
			index: true,
			path: 'profiles/payment',
			lazy: async () => ({ Component: (await import('@/modules/settings/pages/PaymentProfiles')).default }),
			loader: () => ({
				meta: {
					title: 'Payment Profiles',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'twilioProfiles',
			index: true,
			path: 'profiles/twilio',
			lazy: async () => ({ Component: (await import('@/modules/settings/pages/TwilioProfiles')).default }),
			loader: () => ({
				meta: {
					title: 'Twilio Profiles',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'feeNCost',
			index: true,
			path: 'feeNCost',
			lazy: async () => ({ Component: (await import('@/modules/settings/pages/FeeNCost.tsx')).default }),
			loader: () => ({
				meta: {
					title: 'Fee & Cost',
					disabled: true,
				} as RouteMeta,
			}),
		},
		...awsRoutes,
	],
};

export default route;
