import { forwardRef, ReactNode } from 'react';
import { LoadingOverlay, Paper, PaperProps } from '@mantine/core';
import clsx from 'clsx';

export type TwContainerProps = {
	children: ReactNode;
	full?: boolean;
	fill?: boolean;
	className?: string;
	loading?: boolean;
	xs?: boolean;
	sm?: boolean;
	md?: boolean;
	lg?: boolean;
	xl?: boolean;
	xxl?: boolean;
	huge?: boolean;
	screen?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'huge';
	row?: boolean;
	column?: boolean;
	grid?: boolean;
	id?: string;
} & Omit<PaperProps, 'children' | 'className'>;
export const TwContainer = forwardRef<HTMLDivElement, TwContainerProps>(
	({ id, className, children, loading, screen, grid, column, row, full, fill, xs, sm, md, lg, xl, xxl, huge, ...props }, ref) => {
		column = column || (!row && !grid);
		const s = screen ? screen + ':' : '';
		return (
			<Paper
				id={id}
				ref={ref}
				bg={'transparent'}
				className={clsx(
					'tw-container tw-relative tw-mx-auto tw-flex-grow',
					{
						'tw-flex': !grid,
						'tw-grid': grid,
						'tw-flex-col': column,
						'tw-flex-row': row,
						'tw-w-full tw-max-w-full': full,
						'tw-h-full': fill,
						'tw-max-w-1400px': (!full && !xs && !sm && !md && !xl && !xxl && !huge) || lg,
						[s + 'tw-max-w-540px']: xs,
						[s + 'tw-max-w-800px']: sm,
						[s + 'tw-max-w-1200px']: md,
						[s + 'tw-max-w-1800px']: xl,
						[s + 'tw-max-w-2400px']: xxl,
						[s + 'tw-max-w-3000px']: huge,
					},
					className,
				)}
				{...props}>
				<LoadingOverlay visible={!!loading} />
				{children}
			</Paper>
		);
	},
);
TwContainer.displayName = 'TwContainer';
