import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';
import { states } from '@/lib/storage.ts';

const route: RouteObject = {
	path: 'scraper',
	id: 'scraper',
	loader: () => ({
		meta: {
			title: 'Scraper',
			to: { id: 'scraperHome' },
			htmlTitlePattern: '#title :: Scraper ',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/scraper/Layout')).default }),
	children: [
		{
			id: 'scraperHome',
			index: true,
			path: '',
			lazy: async () => ({ Component: (await import('@/modules/scraper/pages/Home')).default }),
			loader: () => ({
				meta: {
					title: 'Home',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'scraperStateHome',
			index: true,
			path: ':state',
			lazy: async () => ({ Component: (await import('@/modules/scraper/pages/StateHome.tsx')).default }),
			loader: ({ params }) => ({
				meta: {
					title: `${states.find((s) => s.abbreviation === params?.state)?.name || ''}`,
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'scraperStateDB',
			index: true,
			path: ':state/db',
			lazy: async () => ({ Component: (await import('@/modules/scraper/pages/ScraperDB.tsx')).default }),
			loader: ({ params }) => ({
				meta: {
					title: `${states.find((s) => s.abbreviation === params?.state)?.name || ''} Database`,
					disabled: true,
				} as RouteMeta,
			}),
		},
	],
};

export default route;
