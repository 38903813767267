import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'dev',
	loader: () => ({
		meta: {
			title: 'Dev',
			htmlTitlePattern: '#title ',
			to: { id: 'dev' },
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/dev/Layout')).default }),
	children: [
		{
			id: 'dev',
			index: true,
			path: '',
			lazy: async () => ({ Component: (await import('@/modules/dev/pages/Home')).default }),
			loader: () => ({
				meta: {
					title: 'Dev Home',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'userActivityLog',
			index: true,
			path: 'users/activity',
			lazy: async () => ({ Component: (await import('@/modules/dev/pages/UsersLog')).default }),
			loader: () => ({
				meta: {
					title: 'Users Log',
					disabled: true,
				} as RouteMeta,
			}),
		},
		{
			id: 'serverLog',
			index: true,
			path: 'server/log',
			lazy: async () => ({ Component: (await import('@/modules/dev/pages/ServerLog')).default }),
			loader: () => ({
				meta: {
					title: 'Server Log',
					disabled: true,
				} as RouteMeta,
			}),
		},
	],
};

export default route;
